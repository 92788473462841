.home_wrap {
  .bottom_event_banner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 47px;
    color: #ffffff;
    background-color: #000000;
    z-index: 9;

    a {
      display: block;
    }

    &.isFixed {
      position: fixed;
    }

    .inner_wrap {
      display: flex;
      align-items: center;
      height: 47px;
      padding: 0;
      overflow: hidden;

      strong {
        margin-right: 62px;
      }

      .slick-slider {
        width: 100%;

        .slick-slide {
          * {
            height: 47px !important;
            line-height: 47px;
          }
        }
      }

      .slick-vertical,
      .slick-list {
        height: 47px !important;
      }

      img {
        display: none;
      }
    }
  }

  .banner_wrap {
    position: relative;

    .inner_wrap {
      height: 455px;
      padding: 0;
      overflow-x: clip;
    }

    .banner_bg_wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .banner_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    // Main Banner Slick Slider
    .slick-slider {
      height: 100%;

      .slick-list,
      .slick-track,
      .slick-slide > div {
        height: 100%;
      }

      .slick-list {
        overflow: unset;
      }
    }

    .slick-current {
      z-index: 90;
    }

    .slick-dots {
      bottom: 63px;
      left: 100px;
      width: auto;
      z-index: 4;

      li {
        width: 5px;
        height: 5px;
        margin: 2px;

        button {
          display: block;
          width: 5px;
          height: 5px;
          padding: 0;

          &::before {
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            font-size: 0;
            background: rgba(255, 255, 255, 0.38);
          }
        }

        &.slick-active {
          width: 14px;
          height: 5px;
          border-radius: 3px;
          background-color: #ffffff;

          button {
            width: 100%;
            height: 5px;

            &::before {
              width: 100%;
            }
          }
        }
      }
    }

    .banner_item {
      position: relative;
      height: 100%;
      padding: 97px 0 0 100px;
      box-sizing: border-box;

      &_info {
        position: relative;
        width: 481px;
        height: 100%;
      }

      &_title {
        margin-bottom: 25px;
        font-size: 34px;
        line-height: 1.29;
        letter-spacing: -1.8px;
        color: #fff;
      }

      &_description {
        height: auto;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: -0.85px;
        white-space: pre-line;
        color: #fff;
      }

      &_image {
        position: absolute;
        top: 82px;
        right: 0;
        width: 585px;
        height: 400px;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .go_class_btn {
        position: absolute;
        bottom: 63px;
        right: 0;
        padding: 14px 37px;
        border-radius: 4px;
        text-align: center;
        color: #ffffff;
        background-color: #f3213c;
        cursor: pointer;
      }
    }
  }

  .inner_wrap {
    padding-top: 80px;
    padding-bottom: 224px;

    .lectures_wrap {
      margin-bottom: 60px;

      .lecture_items {
        display: flex;

        .lecture_item {
          width: 276px;
          margin-right: 30px;
          cursor: pointer;

          .thumbnail_wrap {
            position: relative;

            a {
              display: block;
              height: 100%;
            }

            .thumbnail {
              display: block;
              height: 208px;
              border-radius: 4px;
              overflow: hidden;

              img {
                width: 100%;
                border-radius: 4px;
              }
            }
          }

          .lecture_item_title {
            position: relative;
            margin-bottom: 7px;
            padding-top: 27px;
            font-size: 14px;
            letter-spacing: -0.25px;
            word-break: break-word;
            white-space: pre-wrap;
            color: #000000;

            &.ellipsis {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .discount {
              position: absolute;
              top: 4px;
              left: 0;
              padding: 4px 10px;
              border-radius: 9.5px;
              font-size: 10px;
              font-weight: 500;
              line-height: 1;
              letter-spacing: -0.36px;
              text-align: center;
              color: #fff;
              background-color: #f3213c;
            }
          }

          .lecture_item_description {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            letter-spacing: -0.34px;
            color: #a2a2a2;

            .lecture_item_category {
              display: flex;
              flex-wrap: wrap;

              li {
                margin-bottom: 7px;
                margin-right: 10px;
                padding: 3px 16px;
                border-radius: 10px;
                font-size: 11px;
                letter-spacing: -0.4px;
                text-align: center;
                color: #f24462;
                background-color: rgba(255, 101, 121, 0.07);

                &:last-child {
                  margin-right: 0;
                }
              }
            }

            &.isRow {
              flex-direction: column;

              .lecture_item_category {
                margin-top: 7px;
              }
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .youtube_item_thumbnail img,
    .event_item_thumbnail img {
      border-radius: 4px;
      object-fit: cover;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
    font-size: 24px;
    letter-spacing: -0.4px;
    color: #000;

    .more_btn {
      a {
        font-size: 11px;
        font-weight: normal;
        letter-spacing: -0.4px;
        color: #090909;
      }

      img {
        width: 5px;
        margin-left: 8px;
      }
    }
  }

  .go_youtube_wrap {
    margin-bottom: 60px;

    .youtube_items {
      display: flex;

      .youtube_item {
        width: 276px;
        margin-right: 30px;
        cursor: pointer;

        a {
          display: block;
        }

        &_thumbnail {
          position: relative;
          display: block;
          height: 152px;
          margin-bottom: 12px;
          border-radius: 4px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;

            &.play_icon {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 43px;
              height: auto;
              transform: translate(-50%, -50%);
            }
          }
        }

        &_title {
          font-size: 14px;
          letter-spacing: -0.25px;
          color: #000;
        }

        p {
          font-size: 10px;
          letter-spacing: -0.4px;
          color: #a2a2a2;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .event_banner_wrap {
    // Event Banner Slick Slider
    .slick-slider {
      .slick-disabled {
        display: none !important;
      }

      .slick-track {
        margin-left: 0 !important;
      }

      .slick-prev,
      .slick-next {
        width: 15px;
        transform: translateY(-50%);

        img {
          width: 100%;
        }

        &::before {
          display: none;
        }
      }
    }

    .event_item {
      width: 378px;
      font-size: 0;
      cursor: pointer;

      a {
        display: block;
      }

      &_thumbnail {
        display: block;
        height: 208px;
        margin-bottom: 16px;
        border-radius: 4px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &_title {
        margin-bottom: 7px;
        font-size: 14px;
        letter-spacing: -0.23px;
        color: #000000;
      }

      .dday {
        display: inline-block;
        padding: 1px 16px;
        border-radius: 10px;
        font-size: 11px;
        letter-spacing: -0.4px;
        text-align: center;
        color: #f24462;
        background-color: rgba(255, 101, 121, 0.07);
        box-sizing: border-box;
      }
    }
  }

  // Mobile
  @media screen and (max-width: 767px) {
    width: 100%;
    overflow: hidden;

    .bottom_event_banner {
      height: 47px;
      padding: 0 15px;
      overflow: hidden;
      box-sizing: border-box;

      .inner_wrap {
        height: 47px;

        .slick-slider {
          width: calc(100% - 80px);
        }

        strong {
          margin-right: 21px;
        }
      }
    }

    .banner_wrap {
      .inner_wrap {
        height: 384px;
        padding: 43px 24px 0;
      }

      // Main Banner Slick Slider
      .slick-slider {
        height: 100%;

        .slick-list,
        .slick-track,
        .slick-slide > div {
          height: 100%;
        }

        .slick-list {
          overflow: unset;
        }
      }

      .slick-dots {
        bottom: 188px;
        left: 0;

        li {
          width: 46px;
          height: 1px;
          margin: 0;
          background-color: rgba(255, 255, 255, 0.51);

          button {
            display: none;
          }

          &.slick-active {
            width: 46px;
            height: 2px;
            border-radius: 0;
            background-color: #ffffff;
          }

          button {
            width: 100%;
            height: 12px;

            &::before {
              width: 100%;
              height: 12px;
              line-height: 12px;
              color: rgba(255, 255, 255, 0.9);
            }
          }
        }
      }

      .banner_item {
        position: relative;
        height: 100%;
        padding: 0;
        box-sizing: border-box;

        &_info {
          position: relative;
          width: 100%;
          height: auto;
        }

        &_title {
          margin-bottom: 2px;
          font-size: 24px;
          line-height: 1.38;
          letter-spacing: -0.8px;
          white-space: pre-line;
        }

        &_description {
          height: auto;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.75;
          letter-spacing: -0.85px;
          white-space: pre-line;
          color: #fff;
        }

        &_image {
          position: absolute;
          top: unset;
          bottom: -40px;
          width: 100%;
          height: 203px;
        }

        .go_class_btn {
          display: none;
        }
      }
    }

    .inner_wrap {
      padding-top: 53px;
      padding-bottom: 44px;

      .lectures_wrap {
        margin-bottom: 34px;

        .lecture_items {
          .slick-slider {
            overflow: hidden;
          }

          .lecture_item {
            width: 150px;
            margin-right: 12px;

            &:last-child {
              margin-right: 12px;
            }

            .thumbnail_wrap {
              .thumbnail {
                width: 100%;
                height: 100%;
                object-fit: cover;
                margin-bottom: 0px;
              }
            }

            .lecture_item_title {
              padding-top: 25px;
              letter-spacing: -0.55px;

              .discount {
                top: 5px;
                padding: 3px 9px;
                font-size: 9px;
                letter-spacing: -0.33px;
              }
            }

            .lecture_item_description {
              font-size: 11px;
              letter-spacing: -0.41px;

              .lecture_item_category {
                li {
                  margin-bottom: 7px;
                  margin-right: 6px;
                  padding: 3px 14px;
                  font-size: 9px;
                  letter-spacing: -0.5px;

                  &:last-child {
                    margin-right: 0;
                  }
                }
              }

              &.isRow {
                .lecture_item_category {
                  margin-top: 5px;
                }
              }
            }
          }
        }
      }
    }

    .title {
      margin-bottom: 17px;
      font-size: 17px;
      white-space: pre-wrap;

      .more_btn {
        display: none;
      }
    }

    .go_youtube_wrap {
      margin-bottom: 37px;

      .youtube_items {
        .slick-slider {
          overflow: hidden;
        }

        .youtube_item {
          width: 258px;
          margin-right: 12px;

          &_thumbnail {
            height: 151px;
            margin-bottom: 13px;
          }

          &_title {
            letter-spacing: -0.55px;
          }

          p {
            letter-spacing: -0.38px;
          }

          &:last-child {
            margin-right: 12px;
          }
        }
      }
    }

    .event_banner_wrap {
      margin-bottom: 15px;
      // Event Banner Slick Slider
      .event_item {
        width: 100%;

        &_thumbnail {
          height: 172px;
          margin-bottom: 12px;
        }
      }

      .slick-slider {
        .slick-prev,
        .slick-next {
          top: -26px;
          width: 4px;
        }

        .slick-prev {
          left: unset;
          right: 44px;
        }

        .slick-next {
          right: 0;
        }
      }
    }
  }
}
