.makers_wrap {
  padding: 42px 0 137px;

  .inner_wrap {
    .header_wrap {
      border: none;

      h2 {
        font-size: 24px;
        letter-spacing: -0.4px;
        color: #000000;
        font-weight: bold;
      }
    }

    .search_makers_wrap {
      .filter_select_wrap {
        padding: 24px 0 16px;
        display: flex;
        justify-content: left;

        .filter_select_box_wrap {
          + .filter_select_box_wrap {
            margin-left: 16px;
          }

          .filter_selected_option {
            position: relative;
            display: inline-block;
            min-width: 97px;
            padding: 10px 22px;
            border-radius: 5px;
            border: solid 1px #e5e5e5;
            font-size: 14px;
            letter-spacing: -0.5px;
            text-align: center;
            color: #000000;
            cursor: pointer;
            box-sizing: border-box;

            &:hover {
              border: solid 1px #f22672;
            }

            &.foreignMinWidth {
              min-width: 185px;
            }

            span {
              display: flex;
              justify-content: space-between;
              align-items: center;

              img {
                width: 7px;
              }
            }

            .filter_select_box_options {
              li {
                &:hover {
                  color: #f22672;
                }
              }
            }
          }
        }
      }

      .selected_options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0 11px;
        border-top: 1px solid #f3f3f3;
        border-bottom: 1px solid #f3f3f3;

        ul {
          display: flex;
          justify-content: left;
          flex-direction: row;
          flex-wrap: wrap;
          max-width: 90%;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 16px;
            margin-bottom: 4px;
            padding: 9px 10px 8px 21px;
            border-radius: 5px;
            font-size: 12px;
            letter-spacing: -0.2px;
            color: #ffffff;
            background-color: #f24462;
            box-sizing: border-box;
            list-style: none;
            min-width: 130px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            :first-child {
              width: 70px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .delete_option_btn {
              width: 10px;
              margin-left: 21px;
              cursor: pointer;

              img {
                width: 100%;
              }
            }
          }
        }

        .reset_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 110px;
          margin-bottom: 4px;
          padding: 10px 0;
          border-radius: 5px;
          border: 1px solid #e5e5e5;
          font-size: 14px;
          letter-spacing: -0.5px;
          text-align: center;
          color: #000000;
          cursor: pointer;
          box-sizing: border-box;

          img {
            width: 10px;
            margin-left: 12px;
          }

          &:hover {
            border: solid 1px #f22672;
          }
        }
      }
    }

    .makers_content {
      //border-top: 1px solid #f3f3f3;

      .sort_select_box_wrap {
        padding: 16px 0 24px;
        text-align: right;

        .total_count {
          float: left;
          line-height: 39px;
          color: #a2a2a2;
        }

        .sort_selected_option {
          position: relative;
          display: inline-block;
          min-width: 90px;
          padding: 10px 4px;
          font-size: 14px;
          letter-spacing: -0.5px;
          text-align: center;
          color: #000000;
          cursor: pointer;
          box-sizing: border-box;

          &:hover {
            color: #f22672;
          }

          &.foreignMinWidth {
            min-width: 185px;
          }

          span {
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
              width: 12px;
            }
          }
        }

        .sort_select_box_options {
          position: absolute;
          top: 42px;
          right: 0;
          width: 100%;
          border-radius: 5px;
          border: solid 1px #e5e5e5;
          background-color: #ffffff;
          z-index: 2;

          li {
            padding: 6px 0;
            font-size: 14px;
            letter-spacing: -0.5px;
            text-align: center;
            color: #000;
            cursor: pointer;

            &:hover {
              color: #f22672;
            }
          }
        }
      }

      .makers_list {
        display: flex;
        max-width: 1200px;
        flex-wrap: wrap;
        justify-content: space-between;

        .makers_item_wrap {
          max-width: 268px;
          overflow: hidden;
          margin-bottom: 80px;
          border: 1px solid #bebebe;

          &_hidden {
            opacity: 0;
            width: 268px;
          }

          &:first-child {
            margin-left: 0;
          }

          &:nth-child(4n) {
            margin-right: 0;
          }

          .makers_thumbnail_wrap {
            width: 100%;
            max-height: 268px;

            img {
              width: 268px;
              aspect-ratio: 1/1;
              margin: 0;
            }

            .makers_thumbnail_no_image {
              width: 268px;
              aspect-ratio: 1/1;
              margin: 0;
              background: #bebebe;
            }
          }

          .makers_info_wrap {
            padding: 14px 8px;

            .makers_title {
              font-weight: bold;
              font-size: 20px;
              height: 52px;
              line-height: 26px;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .makers_maker_name {
              margin-top: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .makers_pubdate {
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .makers_wrap {
    padding: 0 0 40px;

    .inner_wrap {
      .header_wrap {
        display: none;
      }

      .search_makers_wrap {
        .filter_select_wrap {
          .filter_select_box_wrap {
            .filter_selected_option {
              font-size: 12px;
              padding: 8px 10px;
              width: 80px;
            }
          }
        }

        .selected_options {
          ul {
            li {
              font-size: 12px;
              min-width: 20px;
              max-width: 70px;
              padding: 6px 8px;
              margin-right: 8px;

              :first-child {
                display: block;
                width: 100%;
              }

              span {
                &.delete_option_btn {
                  margin-left: 4px;
                }

                img {
                  margin: 0;
                  display: none;
                  width: 8px !important;
                  aspect-ratio: 1/1;
                  float: right;
                }
              }
            }
          }

          .reset_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            min-width: 80px;
            margin-bottom: 4px;
            padding: 8px 0;
            border-radius: 5px;
            border: 1px solid #e5e5e5;
            font-size: 12px;
            letter-spacing: -0.5px;
            text-align: center;
            color: #000000;
            cursor: pointer;
            box-sizing: border-box;

            img {
              width: 10px;
              margin-left: 12px;
            }
          }
        }
      }

      .makers_content {
        .search_makers_wrap {
          .makers_categories_wrap {
            ul {
              display: flex;

              li {
                box-sizing: border-box;

                &:last-child {
                  margin: 0;
                }
              }
            }

            .makers_categories {
              padding: 15px 0;
              border-top: 1px solid #f3f3f3;
            }

            .top_categories {
              ul {
                li {
                  margin-right: 32px;
                  font-size: 14px;
                  font-weight: 500;
                  letter-spacing: -0.25px;
                  color: #bebebe;
                  cursor: pointer;

                  &.isActive {
                    color: #000000;
                  }
                }
              }
            }

            .middle_categories {
              &.isBorderBottom {
                border-bottom: 1px solid #f3f3f3;
              }

              ul {
                li {
                  margin-right: 16px;
                  padding: 7px 31px;
                  border-radius: 5px;
                  font-size: 12px;
                  letter-spacing: -0.2px;
                  color: #000000;
                  background-color: rgba(178, 178, 178, 0.06);
                  cursor: pointer;

                  &.isActive {
                    font-weight: 500;
                    color: #ffffff;
                    background-color: #f24462;
                  }
                }
              }
            }
          }
        }

        .sort_select_box_wrap {
          padding: 15px 0;

          .sort_selected_option {
            min-width: 74px;
            padding: 5px 4px;
            font-size: 11px;
            letter-spacing: -0.39px;
            border: none;

            &.foreignMinWidth {
              min-width: 146px;
            }
          }

          .sort_select_box_options {
            top: 30px;

            li {
              font-size: 11px;
              letter-spacing: -0.39px;
            }
          }

          .total_count {
            line-height: 28px;
            font-size: 14px;
          }
        }

        .makers_list {
          width: 100%;

          .makers_item_wrap {
            width: 47%;
            margin-bottom: 28px;

            &.hidden {
              width: 100%;
            }

            .makers_thumbnail_wrap {
              img {
                width: 100%;
                aspect-ratio: 1/1;
              }

              .makers_thumbnail_no_image {
                width: 100%;
                aspect-ratio: 1/1;
              }
            }

            .makers_info_wrap {
              font-size: 12px;
              padding: 8px 6px;

              .makers_title {
                font-size: 14px;
                height: 44px;
              }

              .makers_maker_name {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
}

.modal_outside {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(50, 50, 50, 0.21);
  z-index: 500;
}
