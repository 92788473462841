.profile__wrapper {
  width: 100%;
  max-width: 840px;

  .title {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 767px) {
    padding: 0 24px;
    box-sizing: border-box;
  }
}

.profile {
  display: flex;
  width: 100%;
  flex-direction: column;

  .message {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.93;
  }

  .inquiry_link {
    align-self: flex-end;
    margin-top: 50px;
  }

  .inquiry_btn {
    background-color: #f24462;
  }

  .signout_link {
    align-self: flex-end;
    margin-top: 20px;
    font-size: 12px;
    color: #bebebe;
    text-decoration: underline;
  }

  @media screen and (max-width: 767px) {
    align-items: center;

    .form-group__field-checkbox .label-sub {
      white-space: normal !important;
    }

    .message {
      text-align: center;
      font-size: 12px;
      white-space: pre-wrap;
      line-height: normal;
    }
    .message:first-of-type {
      margin-top: 200px;
    }
    .message + .message {
      margin-top: 18px;
    }

    .inquiry_link {
      order: 2;
      align-self: unset;
      margin-top: 45px;
      width: 100%;
    }
    .inquiry_btn {
      width: 100%;
      height: 57px;
    }
    .signout_link {
      order: 1;
      align-self: unset;
      margin-top: 180px;
    }
  }

  form {
    .form-group__field-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .form-group__field {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      column-gap: 16px;
    }

    .form-group__field-checkbox {
      display: flex;
      align-items: flex-end;
    }

    .form-group__field-interested-companies {
      display: flex;
      align-items: flex-start;
      margin-top: 10px;
    }

    .checkbox__filed {
      display: flex;
      width: 300px;
      justify-content: flex-end;
      column-gap: 16px;

      .checkbox {
        max-width: 100%;

        img {
          max-width: none;
        }
      }
    }

    .checkbox__filed button {
      display: flex;
      align-items: center;
      line-height: 1;
    }

    .checkbox__filed .checkbox {
      margin: 0;
      margin-right: 5px;
    }

    #is_marketing_on {
      margin-top: 15px;

      button:last-child {
        color: rgb(128, 128, 128);
      }
    }

    .form-group__field-checkbox .label-sub {
      white-space: nowrap;
    }

    .form-group__field label {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: bold;
      color: #000000;
      width: 200px;
      line-height: 42px;
    }

    .form-group__field .label-sub {
      display: inline;
      top: 19px;
    }

    .form-group__field .label-wrap {
      font-size: 14px;
      font-weight: bold;
      color: #000000;
      width: 200px;
    }

    .form-group__field .label-sub {
      font-size: 12px;
      font-weight: normal;
      color: #838383;
      line-height: 0.5em;
    }

    .form-group__field input {
      width: 300px;
      height: 42px;
      padding: 8px;
      border: 1px solid #cccccc;
      border-radius: 5px;
      font-size: 14px;
      color: #000000;
      outline: none;
      transition: border 0.2s ease-in-out;
    }

    .form-group__field input.error {
      border: 1px solid #ff0000;
      transition: border 0.3s ease-in-out;
    }

    .form-group__field .btn-check {
      background-color: #f24462;
      opacity: 0;
      color: #ffffff;
      border-radius: 5px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      width: fit-content;
      font-size: 14px;
      line-height: 42px;
      cursor: default;
      pointer-events: none;
      transition: opacity 0.3s ease-in-out;
    }

    .form-group__field .btn-check.active {
      opacity: 1;
      pointer-events: auto;
      transition: opacity 0.3s ease-in-out;
      cursor: pointer;
    }

    .form-group__field .btn-check.active.disabled,
    .form-group__field .btn-check.active.needCheck {
      cursor: default;
      pointer-events: none;
    }

    .form-group__field .btn-check.active.disabled {
      opacity: 0.5;
    }

    .form-group__field .btn-check.active.needCheck {
      opacity: 0.8;
    }

    .form-group__field .btn-check.hide {
      opacity: 0;
      pointer-events: none;
      cursor: default;
    }

    .form-group__field-error-wrap {
      display: flex;
      column-gap: 16px;
    }

    .form-group__field-error-wrap .empty-first {
      width: 200px;
    }

    .form-group__field-error-wrap .form-group__field-error {
      color: #ff0000;
      font-weight: bold;
      font-size: 12px;
      height: 14px;
      line-height: 14px;
      padding-left: 4px;
      margin-top: 4px;
      margin-bottom: -18px;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }

    .form-group__field-error-wrap .form-group__field-error.active {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }

    .interested-companies {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 300px;

      .interested-companies-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        height: 70px;
        overflow-y: scroll;
        background-color: #f9f9f9;
        border: 1px solid #ddd;
        padding: 10px;
        border-radius: 5px;

        // 웹킷 기반 브라우저에서 스크롤바 항상 표시
        &::-webkit-scrollbar {
          width: 12px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #ccc;
          border-radius: 6px;
        }

        &::-webkit-scrollbar-track {
          background-color: #f9f9f9;
        }
      }

      .interested-company-item {
        display: flex;
        align-items: center;
        height: 40px;
        background-color: #f5f5f5;
        color: #333;
        padding: 8px 12px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: bold;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }

      .interested-company-btn {
        background-color: #f24462;
        color: #ffffff;
        padding: 8px 16px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        transition: background-color 0.3s ease-in-out;
        align-self: flex-start;

        &:hover {
          background-color: #d93b55;
        }

        &:disabled {
          background-color: #cccccc;
          cursor: not-allowed;
        }
      }
    }

    .label-sub {
      white-space: normal;
    }
  }
}

.signout {
  display: flex;
  width: 100%;
  max-width: 660px;
  flex-direction: column;
  align-items: baseline;

  .message {
    position: relative;
    // margin-bottom: 30px;
    // padding: 22px 0px;
    margin-bottom: 24px;
    font-size: 17px;
    font-weight: 500;
    // border: 1px solid red;
    // background-color: white;
    // border-radius: 3px;
    // border-bottom-left-radius: 0;
    // border: solid 1px #e0e0e0;
  }

  // .message:before {
  //   position: absolute;
  //   top: 100%;
  //   left: -1px;
  //   border-top: 12px solid #e0e0e0;
  //   border-right: 18px solid transparent;
  //   border-bottom: 0;
  //   border-left: 0;
  //   content: "";
  // }
  // .message:after {
  //   position: absolute;
  //   top: 100%;
  //   left: 0;
  //   border-top: 10px solid white;
  //   border-right: 15px solid transparent;
  //   border-bottom: 0;
  //   border-left: 0;
  //   content: "";
  // }

  .terms {
    margin-bottom: 24px;
    padding: 27px 30px;
    width: 100%;
    border-radius: 5px;
    background-color: #f8f8f8;
  }

  .term {
    font-size: 14px;
    font-weight: 500;
    line-height: 2.57;
    list-style-type: '- ';
  }

  .agree {
    margin-bottom: 51px;
    display: flex;
    align-items: center;
    cursor: pointer;

    input[type='checkbox'] {
      display: none;
    }

    input[type='checkbox'] + .checkbox-img {
      display: block;
      flex-shrink: 0;
      width: 16px;
      min-width: 16px;
      height: 16px;
      background-image: url('../../../Assets/Images/icon-checkbox-unchecked.png');
      background-repeat: no-repeat;
      background-size: cover;
    }

    input[type='checkbox']:checked + .checkbox-img {
      background-image: url('../../../Assets/Images/icon-checkbox-checked.png');
    }

    span {
      margin-left: 11px;
      font-size: 12px;
      user-select: none;
    }
  }

  .form_link {
    display: flex;
    align-items: center;
    margin-bottom: 28px;
    margin-left: auto;

    span {
      font-size: 12px;
      border-bottom: 1px solid black;
    }

    &::after {
      margin-left: 6px;
      content: url('../../../Assets/Images/icon-dropdown-right-gray.png');
    }
  }

  .signout_btn {
    margin-left: auto;
    padding: 10px 42px;
    background-color: #444;
  }

  @media screen and (max-width: 767px) {
    .message {
      margin-top: 62px;
      margin-bottom: 46px;
      text-align: center;
      font-size: 15px;
    }

    .terms {
      padding: 18px 14px 18px 24px;
    }
    .term {
      font-size: 13px;
      line-height: 1.54;
    }
    .term + .term {
      margin-top: 16px;
    }

    .agree {
      margin-bottom: 200px;
    }

    .signout_btn {
      width: 100%;
      height: 57px;
    }
  }
}
