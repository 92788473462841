.search_records_modal_wrap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  background-color: #ffffff;
  box-sizing: border-box;
  z-index: 999;

  .records_filters_wrap {
    position: relative;
    padding: 36px 24px 87px;

    .close_options_modal_btn {
      position: absolute;
      top: 35px;
      right: 38px;
      cursor: pointer;
      width: 15px;

      img {
        width: 100%;
      }
    }

    .top_filters {
      ul {
        display: flex;
        border-bottom: 1px solid #ececec;

        li {
          margin-right: 20px;
          padding: 18px 0;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: -0.55px;
          color: #a2a2a2;
          cursor: pointer;

          &.isActive {
            color: #000000;
          }
        }
      }
    }

    .middle_filters {
      height: 200px;
      overflow-y: scroll;
      margin: 8px 0 60px;

      ul {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;

        li {
          width: 100%;
          margin-right: 8px;
          padding: 10px 27px 4px;
          border-radius: 5px;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.2px;
          text-align: left;
          color: #000000;
          cursor: pointer;

          img {
            height: 18px;
            margin-right: 10px;
          }

          div {
            display: inline-block;
            height: 14px;
            line-height: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 85%;
          }

          &.isActive {
            font-weight: 500;
            color: #ffffff;
            background-color: #f24462;
          }
        }
      }
    }

    .selected_filters {
      position: absolute;
      bottom: 87px;
      left: 24px;
      display: flex;
      width: calc(100% - 48px);
      justify-content: space-between;

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 16px 0;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.34px;
        text-align: center;
        cursor: pointer;

        &.reset_btn {
          margin-right: 9px;
          padding-left: 17px;
          padding-right: 20px;
          border: solid 1px #e5e5e5;
          color: #a2a2a2;

          img {
            width: 10px;
            margin-top: -1px;
            margin-left: 4px;
          }
        }

        &.submit_btn {
          flex: 1;
          color: #fff;
          background-color: #f24462;
        }
      }
    }
  }
}
