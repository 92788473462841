.review-write-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;

  .form {
    height: 100%;
    width: 100%;
    max-width: 360px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h1 {
      font-size: 1.125rem;
      font-weight: 700;
    }

    h2 {
      font-size: 0.9375rem;
      font-weight: 700;
    }

    .form-content {
      font-size: 0.875rem;
      text-align: left;

      textarea {
        border: 1px solid #bebebe;
        border-radius: 0.5rem;
        width: 100%;
        min-height: 100px;
        padding: 0.75rem;
        font-size: 0.75rem;

        &.error {
          border: 2px solid #f24462;
        }
      }

      .form-content-general {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #d8d8d8;
        gap: 0.25rem;
        padding: 1rem 0;
      }

      .form-content-detail {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding: 1rem 0;

        .form-content-detail-title {
          display: flex;
          column-gap: 10px;

          img {
            width: 2rem;
            height: 2rem;
          }

          p {
            font-size: 0.75rem;
            font-weight: 400;

            br {
              display: none;
            }
          }
        }

        .form-content-detail-content,
        .form-content-detail-time,
        .form-content-detail-tutor {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .rating-star {
      width: 20px;
      aspect-ratio: 1;
      cursor: pointer;
    }

    .MuiRating-root {
      label {
        .MuiRating-iconFilled,
        .MuiRating-iconEmpty {
          svg {
            width: 20px;
            height: 20px;
          }
        }

        .MuiRating-iconFilled {
          svg {
            color: #fadf4a;
          }
        }

        .MuiRating-iconEmpty {
          svg {
            color: #ececec;
          }
        }
      }
    }
  }

  button {
    width: 100%;
    height: 3rem;
    border-radius: 0.5rem;
    background-color: #f24462;
    color: #ffffff;
    font-size: 0.875rem;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:disabled {
      background-color: #ececec;
      color: #bebebe;
      cursor: not-allowed;
    }
  }
}
