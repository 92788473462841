.coupon_item {
  float: left;
  width: 408px;
  margin-right: 30px;
  padding: 21px 18px 16px;
  border-radius: 5px;
  border: solid 1px #ececec;
  box-sizing: border-box;

  &:nth-child(even) {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .coupon_price {
    font-size: 20px;
    letter-spacing: -0.43px;
    color: #f24462;

    &.expired {
      color: #a2a2a2;
    }
  }

  .coupon_title {
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.8px;
    color: #000000;
  }

  .coupon_info {
    display: flex;
    justify-content: space-between;

    .due {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.69px;
      color: #a2a2a2;

      span {
        margin-right: 2px;
      }
    }

    .check_btn {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.69px;
      color: #a2a2a2;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 8px;
    margin-right: 0;
    padding: 22px 15px 24px;

    &:nth-child(even) {
      margin-right: 0;
      margin-bottom: 8px;
    }

    .coupon_price {
      font-size: 17px;
    }

    .coupon_title {
      margin-bottom: 24px;
      font-size: 12px;
    }

    .coupon_info {
      display: flex;
      justify-content: space-between;

      span {
        font-size: 10px;
      }
    }
  }
}
