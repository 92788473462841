.pagination_wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  .arrow {
    display: inline-block;
    width: 21px;
    height: 21px;
    cursor: pointer;

    img {
      width: 100%;
    }

    &.prev-arrow {
      margin-right: 10px;
    }

    &.next-arrow {
      margin-left: 10px;
    }
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;

    .page-item {
      margin-right: 10px;
      padding: 4px 8px;
      font-size: 14px;
      letter-spacing: -0.4px;
      cursor: pointer;
      color: #cccccc;

      &.isActive {
        font-weight: bold;
        color: #000000;
      }
    }
  }
}
