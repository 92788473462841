.my_page_mobile_header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 46px;
  padding: 0 21px;
  border-bottom: 1px solid #ececec;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.51px;
  color: #000000;
  box-sizing: border-box;

  img {
    position: absolute;
    top: 50%;
    left: 21px;
    width: 9px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
