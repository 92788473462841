#popup-root {
  .popup-content {
    z-index: 0;
    padding: 91px 69px 69px 69px;
    box-sizing: border-box;
    width: auto;
    min-width: 438px;
    text-align: center;
    border: none;
    border-radius: 5px;
    overflow: auto;
  }

  .popup-close {
    position: absolute;
    z-index: 1;
    top: 38px;
    right: 30px;
    width: 15px;
    cursor: pointer;
  }

  .popup-diagonals {
    pointer-events: none;
  }

  .popup-diagonal {
    position: absolute;
    z-index: -1;
  }

  .popup-diagonal-left {
    top: 50%;
    left: 0;
  }

  .popup-diagonal-right-top {
    top: calc(50% - 90px);
    right: 0;
  }

  .popup-diagonal-right-bottom {
    top: 50%;
    right: 0;
  }

  .popup-title {
    font-size: 20px;
    font-weight: bold;

    & + .popup-msg,
    & + .popup-buttons {
      margin-top: 28px;
    }
  }

  .popup-msg {
    font-size: 14px;
    font-weight: 500;
    white-space: pre-wrap;

    & + button,
    & + .popup-buttons {
      margin-top: 67px;
    }
  }

  .popup-buttons {
    display: flex;
  }

  button {
    padding: 19px 0;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: white;
    background-color: #f24462;
    border: none;
    border-radius: 5px;
    outline: none;
    cursor: pointer;

    & + button {
      margin-left: 6px;
    }
  }

  .popup-button--cancel {
    background-color: #999;
  }

  @media screen and (max-width: 767px) {
    .popup-content {
      min-width: unset;
      max-width: 100vw;
      max-height: 80vh;
      padding: 50px 30px 33px 30px;
      position: relative;
    }

    .popup-close {
      width: 12px;
      top: 19px;
      right: 15px;
    }

    .popup-msg {
      font-size: 12px;
    }

    .popup-buttons {
      flex-direction: column-reverse;
    }
    button + button {
      margin-left: 0;
      margin-bottom: 8px;
    }

    .mobile-popup-content {
      margin-bottom: 0 !important;
      width: 100vw;
      border-radius: 33px 33px 0 0;

      .popup-close {
        width: 15px;
        top: 32px;
        right: 30px;
      }
    }
  }
}
