.org-lecture-wrap {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
  }

  section {
    h4 {
      display: none;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    .lecture-list-container {
      font-size: 14px;

      .lecture-title-wrap {
        width: 280px;
      }

      .lecture-schedule-wrap {
        width: 200px;
      }

      .lecture-completion-rate-wrap {
        width: 100px;

        > span {
          display: inline-block;
          width: 100%;
          text-align: center;
        }
      }

      .lecture-action-wrap {
        width: 100px;
        display: flex;
        justify-content: center;
        items-align: center;
      }

      .lecture-list-header {
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #ececec;
        padding: 0.5rem 6px 0.5rem 0;

        > div {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 8px;

          &:last-child {
            border-right: 0;
          }

          &.lecture-status-wrap {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
          }
        }
      }

      .lecture-list-body {
        .lecture-list-wrap {
          max-height: 480px;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;

          &::-webkit-scrollbar {
            -webkit-appearance: none;

            &:vertical {
              width: 6px;
            }

            &-thumb {
              border-radius: 4px;
              background-color: rgba(0, 0, 0, 0.5);
              -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            }
          }

          li {
            border-bottom: 1px solid #ececec;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 100%;

            > div {
              padding: 8px;

              > span {
                height: 100%;
                line-height: 24px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

                &.lecture-schedule-start,
                &.lecture-schedule-end {
                  text-align: center;
                }
              }

              &.lecture-status-wrap {
                padding-left: 2rem;
              }
            }

            .lecture-status-wrap {
              font-weight: bold;
            }

            .lecture-action-button {
              cursor: pointer;
              font-size: 12px;
              padding: 4px 8px;
              border-radius: 4px;
              border: 1px solid #ececec;
              background-color: #fff;
              transition: all 0.2s ease-in-out;
              color: #222;

              &:hover {
                background-color: #e5e5e5;
              }
            }
          }
        }
      }
    }
  }
}

.lecture-status-wrap,
.lecture-status-guide {
  > div {
    width: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    user-select: none;

    .color-block {
      width: 12px;
      height: 12px;
      border-radius: 4px;
      padding: 0 4px;
    }

    &.lecture-status-ready {
      .color-block {
        margin-top: -2px;
        background-color: #a9a9a9;
      }
    }

    &.lecture-status-ongoing {
      .color-block {
        margin-top: -2px;
        background-color: #1abc9c;
      }
    }

    &.lecture-status-warning {
      .color-block {
        margin-top: -2px;
        background-color: #ff9800;
      }
    }

    &.lecture-status-fail {
      .color-block {
        margin-top: -2px;
        background-color: #ff5555;
      }
    }

    &.lecture-status-done {
      .color-block {
        margin-top: -2px;
        background-color: #2222ff;
      }
    }
  }
}

.lecture-status-wrap {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;

  > div {
    width: 60px;
    column-gap: 6px;
    justify-content: flex-start;
    height: 24px;
    align-items: start;

    > .color-block {
      min-width: 24px;
      width: auto;
      line-height: 26px;
      text-align: center;
      height: 24px;
      color: #fff;
    }
  }
}

.lecture-status-guide {
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  font-size: 12px;
  column-gap: 8px;

  > div {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 4px;
  }
}

.lecture-detail-popup-content {
  .popup-close {
    margin-top: -0.75rem;
  }

  > section {
    margin-top: -2rem;

    .lecture-popup-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h5 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 0.5rem;
      }

      > div {
        min-width: 120px;

        &.lecture-popup-header-action-wrap {
          button {
            font-size: 12px !important;
            height: 32px !important;
            padding: 12px !important;
            background-color: #1976d2 !important;
            border: 1px solid #b9b9b9 !important;

            &:hover {
              background-color: #0054b0 !important;
            }
          }
        }
      }
    }

    .lecture-popup-body {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      row-gap: 2rem;
      text-align: left;

      h5 {
        font-size: 16px;
        font-weight: 700;
      }

      section {
        max-width: 960px;
        overflow: hidden;
      }

      .lecture-students-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        > div {
          min-width: 120px;
        }

        .lecture-students-header-action-wrap {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          column-gap: 0.25rem;

          button {
            font-size: 12px !important;
            height: 32px !important;
            width: auto !important;
            padding: 12px !important;
            opacity: 0.85;
            //background-color: #1976d2 !important;
            //border: 1px solid #b9b9b9 !important;

            &.btn-cancel {
              background-color: #ff5555 !important;
              border: 1px solid #ff5555 !important;
              color: #fff !important;

              &:disabled {
                background-color: #b9b9b9 !important;
                border: 1px solid #b9b9b9 !important;
              }
            }

            &.btn-add {
              background-color: #1abc9c !important;
              border: 1px solid #1abc9c !important;
              color: #fff !important;

              &:disabled {
                background-color: #b9b9b9 !important;
                border: 1px solid #b9b9b9 !important;
              }
            }

            &.btn-send-sms {
              background-color: #ff8700 !important;
              border: 1px solid #ff8700 !important;
              color: #fff !important;

              &:disabled {
                background-color: #b9b9b9 !important;
                border: 1px solid #b9b9b9 !important;
              }
            }

            &:hover {
              opacity: 1;
            }

            &.hide {
              display: none;
            }
          }
        }
      }
    }
  }
}

.list-wrap {
  min-width: 720px;
  max-width: 960px;

  .checkbox-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
  }

  .list-header,
  .list-body > ul > li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    column-gap: 1rem;
    border-bottom: 1px solid #ececec;
    padding: 0.125rem 6px 0.125rem 0;
    font-size: 14px;
    min-height: 48px;
  }

  .list-header {
    font-weight: 700;

    > div {
      text-align: center !important;
    }
  }

  .list-body {
    ul {
      height: 240px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        -webkit-appearance: none;

        &:vertical {
          width: 6px;
        }

        &-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
      }

      li {
        font-size: 14px;
        padding-right: 0;

        > div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

section.add-student-popup {
  margin: -2rem 0;

  h5 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .add-student-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    margin-top: 2rem;

    button {
      font-size: 12px !important;
      height: 32px !important;
      width: auto !important;
      padding: 12px !important;

      &.btn-cancel {
        background-color: #928c8c !important;
        border: 1px solid #b9b9b9 !important;

        &:hover {
          background-color: #706a6a !important;
        }
      }

      &.btn-add {
        background-color: #1976d2 !important;
        border: 1px solid #b9b9b9 !important;

        &:hover {
          background-color: #0054b0 !important;
        }

        &:disabled {
          background-color: #928c8c !important;

          &:hover {
            background-color: #928c8c !important;
          }
        }
      }
    }
  }
}

.lecture-summary-wrap {
  .lecture-summary-body {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: flex-start;
    column-gap: 1rem;
    margin-bottom: 1rem;

    > section {
      min-width: 200px;
      aspect-ratio: 1/1;
      border-radius: 1rem;
      background-color: #ececec;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: 700;
      color: #000;
      padding: 1.5rem;

      h6 {
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0.5rem;
        text-align: left;
      }

      > div {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 0.25rem;
        font-weight: 400;

        + h6 {
          margin-top: 1.25rem;
        }

        > div {
          text-align: left;

          > span {
            font-weight: 500;
          }
        }
      }
    }
  }
}
