.footer_wrap {
  padding: 58px 0 50px;
  background-color: rgba(223, 223, 223, 0.11);
  box-sizing: border-box;

  .inner_wrap {
    display: flex;
    justify-content: space-between;

    h4 {
      display: flex;
      align-items: center;
      height: 24.3px;
      margin-bottom: 17px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .footer {
    &_info {
      width: 553px;

      .footer_nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 28px;

        .footer_logo {
          width: 31px;
          margin-bottom: 0;
          font-size: 0;

          img {
            width: 100%;
          }
        }

        ul {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
          width: 470px;

          li {
            position: relative;
            min-width: 82px;
            padding: 0 18px;
            text-align: center;

            &::before {
              position: absolute;
              top: 50%;
              right: 0;
              content: '|';
              transform: translateY(-50%);
            }
          }
        }
      }

      .footer_company_information {
        display: block;
        font-size: 10px;
        font-weight: 300;
        letter-spacing: -0.19px;
        color: rgba(0, 0, 0, 0.69);

        .copyright {
          margin-top: 10px;
        }
      }
    }

    &_channels {
      ul {
        li {
          margin-bottom: 2px;

          a {
            font-size: 12px;
            font-weight: normal;
            color: #bebebe;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }

    &_service_center {
      .inquiry_btn {
        display: block;
        width: 277px;
        margin-bottom: 6px;
        border-radius: 4px;
        background-color: rgba(216, 216, 216, 0.18);

        a {
          display: block;
          padding: 11px 0;
          text-align: center;
          font-size: 14px;
          font-weight: bold;
          color: #000000;
        }
      }

      > p {
        font-size: 12px;
        color: #a2a2a2;
      }
    }

    // mobile
    &_mobile_company_information,
    &_mobile_service_center {
      display: none;
    }
  }

  // Mobile
  @media screen and (max-width: 767px) {
    padding: 30px 0 38px;

    .inner_wrap {
      flex-direction: column;

      h4 {
        margin-bottom: 4px;
        font-size: 11px;
        letter-spacing: -0.41px;
        color: #a2a2a2;
      }
    }

    .footer {
      &_info {
        width: 100%;

        .footer_nav {
          flex: 1;
          margin-bottom: 21px;

          .footer_logo {
            display: none;
          }

          ul {
            flex: 1;

            li {
              min-width: 39px;
              padding: 0 8px;
              font-size: 11px;
              letter-spacing: -0.41px;
              color: #a2a2a2;

              &:first-child {
                padding-left: 0;
                text-align: left;
              }

              &:last-child {
                padding-right: 0;
                text-align: right;

                &::before {
                  display: none;
                }
              }
            }
          }
        }

        .footer_company_information {
          display: none;
        }
      }

      &_channels {
        margin-bottom: 22px;

        ul {
          overflow: hidden;

          li {
            float: left;
            width: 33.33%;
            font-size: 11px;
            letter-spacing: -0.41px;

            &:nth-child(3n) {
              text-align: right;
            }

            &:nth-child(3n - 1) {
              text-align: center;
            }
          }
        }
      }

      &_service_center {
        display: none;
      }

      &_mobile_company_information {
        display: block;
        line-height: 1.5;
        font-size: 10px;
        font-weight: 300;
        letter-spacing: -0.38px;
        color: rgba(0, 0, 0, 0.69);
      }

      &_mobile_service_center {
        display: block;
        padding: 20px 0;

        .inquiry_btn {
          display: block;
          width: 100%;
          margin-bottom: 6px;
          border-radius: 4px;
          background-color: rgba(216, 216, 216, 0.18);

          a {
            display: block;
            padding: 11px 0;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            color: #000000;
          }
        }

        > p {
          font-size: 12px;
          color: #a2a2a2;
        }
      }
    }
  }
}
