.login {
  width: 378px;
  margin: 0 auto;
  padding: 94px 0 290px;

  h2 {
    margin-bottom: 11px;
    text-align: center;
    font-size: 24px;
    letter-spacing: -0.4px;
    color: #000;
  }

  p {
    margin-bottom: 5px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.82px;
    color: #000;

    span {
      display: inline-block;
      margin-left: 12px;
      color: #328eff;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .login_country_wrap {
    border: 1px solid #f24462;
    display: flex;
    height: 54px;
    margin-top: 35px;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;

    .login_country_wrap-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      cursor: pointer;
      color: #f24462;
      background-color: white;
      transition: all 0.2s;
    }

    .login_country_wrap-btn-active {
      color: white;
      background-color: #f24462;
    }

    .login_country_wrap-btn:hover {
      opacity: 0.8;
      color: white;
      background-color: #f24462;
    }
  }

  .login_btn_wrap {
    margin-bottom: 83px;

    .social_login_btn,
    .login_btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 54px;
      margin-bottom: 11px;
      padding: 0 17px;
      border-radius: 5px;
      // line-height: 54px;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.7px;
      text-align: center;
      color: #ffffff;
      box-sizing: border-box;
      cursor: pointer;

      &.kakao {
        color: #000000;
        background-color: #ffe600;
      }

      &.naver {
        background-color: #00c300;
      }

      &.google {
        background-color: #db4a39;
      }

      &.facebook {
        background-color: #3a5ca9;
      }

      &:last-child {
        margin-bottom: 0;
      }

      img {
        &.login_logo {
          position: absolute;
          top: 50%;
          left: 17px;
          width: 34px;
          height: 36px;
          transform: translateY(-50%);
        }
      }

      span {
        flex: 1;
      }
    }

    .login_btn {
      margin-bottom: 0;
      color: #000000;
      border: solid 1px #ececec;

      .login_btn-arrow {
        position: absolute;
        right: 25px;
        width: 7px;
        height: 3px;
      }
    }
  }

  #login_form {
    width: 100%;
    margin-top: 20px;

    input {
      display: block;
      width: 100%;
      margin-bottom: 11px;
      padding: 16px 20px 14px;
      border-radius: 5px;
      border: solid 1px #ececec;
      outline: 0 none;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.34px;
      color: #000000;

      &::placeholder {
        color: #bebebe;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .login_btn {
      width: 100%;
      margin-top: 38px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.4px;
      color: #fff;
      background-color: #000;
    }

    .forget-password {
      margin-top: 8px;

      button {
        display: inline-block;
        margin-left: 12px;
        color: #328eff;
      }
    }
  }

  .signup_terms {
    text-align: center;
    font-size: 10px;
    letter-spacing: -0.4px;
    color: #a2a2a2;

    a {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 767px) {
    width: auto;
    min-width: 312px;
    padding: 56px 24px 80px;
    box-sizing: border-box;

    h2 {
      margin-bottom: 26px;
      font-size: 18px;
      letter-spacing: -0.71px;
    }

    p {
      margin-bottom: 9px;
      font-size: 11px;
      letter-spacing: -0.64px;
    }

    .login_country_wrap {
      height: 46px;
      margin-bottom: 26px;
    }

    .login_btn_wrap {
      margin-bottom: 45px;

      .social_login_btn,
      .login_btn {
        height: 46px;
        margin-bottom: 7px;
        line-height: 46px;
        font-size: 13px;
        letter-spacing: -0.57px;
      }
    }

    #login_form {
      width: 100%;
      margin-top: 6px;

      input {
        margin-bottom: 6px;
        padding: 14px 18px 15px;
        font-size: 11px;
        letter-spacing: -0.64px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .login_btn {
        margin-top: 14px;
        font-size: 11px;
        letter-spacing: -0.64px;
      }
    }
  }
}
