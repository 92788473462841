.youtube_embed {
  position: relative;
  width: 66vw;
  padding-top: 60%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
