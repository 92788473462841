.search_page_wrap {
  padding: 50px 0;

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;
    margin-bottom: 24px;

    .search_text {
      color: #f22672;
    }
  }
}

.mobile_search_page {
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;

  > img {
    z-index: 999;
  }

  .mobile_search_input_wrap {
    width: 100%;
    background-image: url('../../Assets/Images/ico-search@2x.png');
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position-x: 0;
    background-position-y: 2px;
    background-color: transparent;
    border: none;
    font-size: 14px;
    outline: none;
    padding-left: 24px;
    margin-left: 20px;
    margin-top: 4px;

    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.search_result_wrap {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  padding: 28px 21px;

  @media (max-width: 767px) {
    padding: 16px 21px;
  }

  &_words {
    &_title_wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      button {
        color: #bebebe;
        font-size: 14px;
      }
    }

    &_list {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;

        button {
          color: #bebebe;
          font-size: 0;
          width: 10px;
          height: 10px;
          background-image: url('../../Assets/Images/icon-cancel-black.png');
          background-repeat: no-repeat;
          background-position: center;
          background-color: transparent;
          background-size: 14px;
          opacity: 0.3;
        }
      }
    }
  }

  .divider {
    margin-top: auto;
    width: 100%;
    height: 1px;
    background-color: #bebebe;
  }

  &_keywords {
    h3 {
      margin-bottom: 20px;
    }

    &_list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 12px;
      column-gap: 12px;
      font-size: 14px;

      li {
        max-width: 50%;

        button {
          width: 100%;
          padding: 6px 12px 4px 12px;
          border-radius: 6px;
          overflow: hidden;
          background: #ebebeb;
        }
      }
    }
  }
}

.search_result_tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @media screen and (max-width: 767px) {
    margin: 0;
  }

  a {
    display: flex;
    width: 50%;
    border: 1px solid #d8d8d8;
    border-right: none;
    justify-content: center;
    padding: 16px 0;
    font-size: 1rem;

    &.active {
      color: #f22672;
      border-color: #f22672;
      border-right: 1px solid #f22672;
    }

    + a {
      border-left: none;
      border-right: 1px solid #d8d8d8;

      &.active {
        border-left: 1px solid #f22672;
      }

      @media screen and (max-width: 767px) {
        border-left: none;
        border-right: none;
        &.active {
          border-left: none;
          border-right: none;
        }
      }
    }

    @media screen and (max-width: 767px) {
      border: none;
      font-size: 14px;
      padding: 10px 0 8px;
      font-weight: 700;
      color: #797979;

      &.active {
        border-left: none;
        border-right: none;
        border-bottom: 2px solid #f22672;
      }
    }
  }
}

.search_result_list {
  padding-top: 50px;

  @media screen and (max-width: 767px) {
    padding-top: 0;
  }

  &_inner_wrap,
  .makers_list {
    display: flex;
    flex-wrap: wrap;
    min-height: 380px;
    column-gap: 16px;
    justify-content: space-between;
  }

  .not_found {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    row-gap: 16px;
    place-items: center;
    justify-content: center;
    padding: 30px 0 80px;
    border-bottom: 1px solid #000000;

    h3 {
      font-size: 20px;
      font-weight: 700;
      line-height: 29px;

      .search_text {
        color: #f22672;
      }
    }

    p {
      color: #bebebe;
      font-size: 1rem;
      font-weight: 400;
      line-height: 23px;
    }
  }

  .related_contents {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 36px;
    padding: 36px 0;

    h3 {
      font-size: 20px;
      font-weight: 700;
      line-height: 29px;
    }
  }
}
