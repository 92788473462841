.login_btn_wrap {
  width: 378px;
  max-width: 100%;
  margin: 3rem 0rem;

  .social_login_btn,
  .login_btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    margin-bottom: 11px;
    padding: 0 17px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.7px;
    text-align: center;
    color: #ffffff;
    box-sizing: border-box;
    cursor: pointer;

    &.kakao {
      color: #000000;
      background-color: #ffe600;
    }

    &.naver {
      background-color: #00c300;
    }

    &.google {
      background-color: #db4a39;
    }

    &:last-child {
      margin-bottom: 0;
    }

    img {
      &.login_logo {
        position: absolute;
        top: 50%;
        left: 17px;
        width: 34px;
        height: 36px;
        transform: translateY(-50%);
      }
    }

    span {
      flex: 1;
    }
  }

  .login_btn {
    margin-bottom: 0;
    color: #000000;
    border: solid 1px #ececec;

    .login_btn-arrow {
      position: absolute;
      right: 25px;
      width: 7px;
      height: 3px;
    }
  }
}