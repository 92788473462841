.org-member-wrap-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
}

section {
  &.member-list-section {
    .member-list-section-header {
      display: flex;
      justify-content: space-between;

      .member-lecture-info-wrap-guide {
        scale: 0.9;
        transform-origin: center right;
        color: #a7a7a7;

        .color-block {
          min-width: 16px;
          width: 16px;
          height: 16px;
          padding: 0;
        }
      }
    }

    + section {
      margin-top: 2rem;
    }

    h4 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    .member-list-container {
      font-size: 14px;

      .member-check-wrap {
        padding-top: 0;
        width: 36px;
        display: none !important;
      }

      .member-name-wrap {
        width: 160px;

        > span {
          text-align: center;
        }
      }

      .member-email-wrap {
        width: 300px;
      }

      .member-phone-wrap {
        width: 200px;

        > span {
          text-align: center;
        }
      }

      .member-lecture-info-wrap {
        flex-grow: 1;
      }

      > .member-list-header {
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 48px;
        border-bottom: 1px solid #ececec;

        > div {
          padding: 2px 8px 0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          &:last-child {
            border-right: 0;
          }
        }
      }

      > .member-list-wrap {
        > .member-list {
          max-height: 240px;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;

          &::-webkit-scrollbar {
            -webkit-appearance: none;

            &:vertical {
              width: 6px;
            }

            &-thumb {
              border-radius: 4px;
              background-color: rgba(0, 0, 0, 0.5);
              -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            }
          }

          li {
            border-bottom: 1px solid #ececec;

            &:hover {
              background-color: #e5e5e5;
            }

            .member-info {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              height: 100%;
              font-size: 14px;

              div {
                padding: 2px 8px 0;

                > span {
                  height: 100%;
                  line-height: 48px;
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                &.member-check-wrap {
                  padding-top: 0;
                  width: 36px;
                }

                &.member-name-wrap {
                  width: 160px;

                  > span {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                  }
                }

                &.member-email-wrap {
                  width: 300px;
                  padding-left: 32px;
                }

                &.member-phone-wrap {
                  width: 200px;

                  > span {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                  }
                }

                &.member-lecture-info-wrap {
                  flex-grow: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}

section.member-list-section
  .member-list-container
  > .member-list-wrap
  > .member-list
  li
  .member-info
  div.member-lecture-info-wrap,
.member-lecture-info-wrap-guide {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    padding: 0 8px 0 0;
    display: flex;
    align-items: center;

    > .color-block {
      display: inline-block;
      min-width: 24px;
      height: 24px;
      border-radius: 4px;
      font-size: 2px;
      margin-right: 4px;
    }

    > span {
      width: auto;
      padding-top: 2px;
    }
  }

  .member-lecture-info-total,
  .member-lecture-info-ready,
  .member-lecture-info-ongoing,
  .member-lecture-info-warning,
  .member-lecture-info-fail,
  .member-lecture-info-done {
    > span {
      line-height: 24px;
      text-align: center;
      font-weight: 700;
      padding: 2px 4px 0;
      cursor: default;
      user-select: none;
    }
  }

  .member-lecture-info-total > .color-block {
    background-color: #5dade2;
    color: #ffffff;
  }

  .member-lecture-info-ready > .color-block {
    background-color: #a9a9a9;
    color: #ffffff;
  }

  .member-lecture-info-ongoing > .color-block {
    background-color: #1abc9c;
    color: #ffffff;
  }

  .member-lecture-info-warning > .color-block {
    background-color: #ff9800;
    color: #ffffff;
  }

  .member-lecture-info-fail > .color-block {
    background-color: #ff5555;
    color: #ffffff;
  }

  .member-lecture-info-done > .color-block {
    background-color: #2222ff;
    color: #ffffff;
  }
}
