.payment_wrap {
  .inner_wrap {
    display: flex;
    justify-content: space-between;
    padding: 65px 0 200px;

    h3 {
      margin-bottom: 22px;
      font-size: 20px;
      letter-spacing: -0.33px;
      color: #000000;
    }
  }

  .payment_form {
    width: 582px;

    .payment_form-title {
      font-size: 24px;
      font-weight: bold;
      height: 36px;
      margin-bottom: 40px;
    }

    h2 {
      margin-bottom: 40px;
      font-size: 24px;
      letter-spacing: -0.4px;
      color: #000000;
    }

    .payment_list_wrap {
      margin-bottom: 32px;

      .payment_list_box {
        padding: 26px 20px;
        border-radius: 5px;
        border: solid 1px #ececec;
      }

      .payment_list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4px;
        text-align: right;

        h4 {
          flex: 1;
          padding-right: 40px;
          font-size: 14px;
          letter-spacing: -0.25px;
          text-align: left;
          color: #000000;
        }

        .price_box {
          display: flex;
          flex-direction: column;

          .price {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: -0.3px;
            color: #000000;

            &.hasDiscount {
              font-size: 12px;
              font-weight: 500;
              letter-spacing: -0.34px;
              color: #bebebe;
              text-decoration: line-through;
            }
          }

          .discounted_price {
            font-size: 16px;
            letter-spacing: -0.3px;
            color: #000000;
          }
        }
      }

      .coupon_select_wrap {
        .coupon_select_box {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 276px;
          padding: 14px 17px;
          border-radius: 5px;
          border: 1px solid #ececec;
          box-sizing: border-box;
          font-size: 12px;
          letter-spacing: -0.48px;
          color: #000000;

          img {
            cursor: pointer;
          }

          &.isNone {
            color: #a2a2a2;
          }

          .coupon_name {
            position: relative;
            width: 80%;
          }

          .clear_icon {
            position: absolute;
            top: 50%;
            right: -9px;
            width: 9px;
            transform: translateY(-50%);
          }

          .coupon_select_box_list {
            position: absolute;
            top: 50px;
            left: 0;
            width: 100%;
            font-size: 12px;
            color: #a2a2a2;
            border-radius: 5px;
            border: 1px solid #ececec;
            background-color: #ffffff;

            &.none_coupon {
              padding: 14px 17px;
              box-sizing: border-box;
            }

            li {
              padding: 8px 17px;
              font-size: 12px;
              font-weight: normal;
              color: #000000;
              box-sizing: border-box;

              &:hover {
                font-weight: bold;
                cursor: pointer;
              }
            }
          }

          .coupon_select_box-icon {
            position: absolute;
            width: 8px;
            right: 17px;
          }
        }
      }
    }

    .payment_implement_list_wrap {
      .payment_implement_list_box {
        overflow: hidden;

        li {
          float: left;
          width: 174px;
          margin-right: 30px;
          margin-bottom: 8px;
          padding: 15px 0 14px;
          border-radius: 5px;
          border: solid 1px #000;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.4px;
          text-align: center;
          color: #000000;
          cursor: pointer;

          &:nth-child(3n) {
            margin-right: 0;
          }

          &.isActive {
            border-color: #f24462;
            color: #f24462;
          }
        }
      }
    }
  }

  .payment_terms_box {
    margin-top: 20px;
    width: 510px;
    padding: 50px 34px 55px;
    border-radius: 5px;
    box-shadow: 0 6px 10px 0 rgba(17, 17, 17, 0.1);

    .payment_terms_box-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 24px;
      height: 29px;
    }

    h3 {
      margin-bottom: 24px;
    }

    .price_history {
      margin-bottom: 33px;
      padding: 27px 30px 24px;
      border-radius: 5px;
      border: solid 1px #ececec;

      .price_history_priceWrap {
        display: flex;
        justify-content: space-between;
        line-height: 2;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.34px;

        .label {
          color: #a2a2a2;
        }

        .price {
          color: #000;
          font-size: 12px;
          font-weight: 500;
        }

        .coupon {
          margin-bottom: 18px;
          color: #f24462;
        }
      }

      .total_price_history {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        border-top: 1px solid #ececec;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.5px;

        .label {
          color: #000;
        }

        .total_price {
          color: #f24462;
        }
      }
    }

    .payment_terms {
      .agreement {
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        input[type='checkbox'] {
          width: 18px;
          height: 18px;
          margin: 0;
          padding: 0;
        }

        strong {
          margin-left: 13px;
          font-size: 16px;
          letter-spacing: -0.5px;
          color: #000000;
        }
      }

      .terms_list_box {
        margin-top: 17px;
        margin-bottom: 47px;
        padding-left: 31px;

        li {
          margin-bottom: 6px;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.4px;
          color: #a2a2a2;

          span {
            margin-left: 7px;
            text-decoration: underline;
            cursor: pointer;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .payment_btn {
      transition: all 0.2s;
      display: block;
      width: 100%;
      padding: 18px 0 19px;
      border: 0 none;
      border-radius: 5px;
      outline: 0 none;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.4px;
      text-align: center;
      color: #ffffff;
      background-color: #f24462;
      cursor: pointer;

      &:disabled {
        background-color: #bebebe;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .inner_wrap {
      flex-direction: column;
      padding: 33px 24px 100px;
      box-sizing: border-box;

      h3 {
        margin-bottom: 0;
        font-size: 16px;
      }
    }

    .payment_form {
      width: 100%;

      h2 {
        display: none;
      }

      .payment_list_wrap {
        margin-bottom: 32px;

        h3 {
          margin-bottom: 11px;
        }

        .payment_list_box {
          padding: 20px 20px 33px;
        }

        .payment_list {
          flex-direction: column;
          justify-content: unset;
          align-items: flex-start;
          text-align: unset;

          h4 {
            margin-bottom: 14px;
            padding: 0;
            font-weight: 500;
            letter-spacing: -0.55px;
          }

          .price_box {
            justify-content: flex-end;
            width: 100%;
            text-align: right;
          }
        }

        .coupon_select_wrap {
          display: flex;
          justify-content: space-between;

          .price_box {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: auto;
            margin-left: 15px;
            text-align: right;

            .price {
              font-size: 16px;
              font-weight: bold;
              letter-spacing: -0.34px;

              &.hasDiscount {
                font-size: 12px;
                font-weight: 500;
                color: #bebebe;
                text-decoration: line-through;
              }
            }

            .discounted_price {
              font-size: 16px;
              letter-spacing: -0.3px;
              color: #000000;
            }
          }

          .coupon_select_box {
            flex: 1;
            width: auto;
            padding: 12px 23px 12px 21px;
          }
        }
      }

      .payment_implement_list_wrap {
        h3 {
          margin-bottom: 22px;
        }

        .payment_implement_list_box {
          overflow: hidden;

          li {
            float: left;
            width: calc(50% - 6px);
            margin-right: 12px;
            margin-bottom: 7px;
            padding: 15px 0 14px;
            font-size: 12px;

            &:nth-child(2n) {
              margin-right: 0;
            }

            &:nth-child(3n) {
              margin-right: 12px;
            }
          }
        }
      }
    }

    .payment_terms_box {
      width: 100%;
      padding: 28px 0 0;
      border-radius: 0;
      box-shadow: none;
      box-sizing: border-box;

      h3 {
        margin-bottom: 24px;
      }

      .price_history {
        margin-bottom: 89px;
        padding-bottom: 15px;
        padding: 0;
        border-radius: 0;
        border: 0 none;

        .price_history_priceWrap {
          display: flex;
          justify-content: space-between;
          line-height: 2;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.34px;

          .coupon {
            margin-bottom: 15px;
          }
        }

        .total_price_history {
          padding-top: 15px;
          font-size: 18px;
        }
      }

      .payment_terms {
        .agreement {
          display: flex;
          align-items: center;

          strong {
            margin-left: 22px;
            font-size: 16px;
          }
        }

        .terms_list_box {
          margin-top: 15px;
          margin-bottom: 36px;
          padding-left: 0;
        }
      }

      .payment_btn {
        padding: 16px 0 14px;
        font-size: 12px;
      }
    }
  }
}
