.complete_wrap {
  position: relative;
  overflow: hidden;

  .complete_wrap-diagonal1 {
    position: absolute;
    width: 306px;
    height: 268px;
    right: 0;
    top: 30px;
  }

  .complete_wrap-diagonal2 {
    position: absolute;
    width: 125px;
    height: 121px;
    right: 0;
    top: 180px;
  }

  .complete_wrap-diagonal3 {
    position: absolute;
    width: 418px;
    height: 319px;
    left: 0;
    top: 270px;
  }

  .inner_wrap {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    padding-top: 108px;
    padding-bottom: 300px;
    box-sizing: border-box;
  }

  // common
  .status_title {
    font-size: 28px;
    letter-spacing: -0.47px;
    text-align: center;
    color: #000000;
  }

  .order_number {
    font-size: 16px;
    letter-spacing: -0.3px;

    strong {
      margin-right: 15px;
      color: #000000;
    }

    span {
      font-weight: 500;
      color: #f24462;
    }
  }

  .message {
    line-height: 1.5;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.7px;
    text-align: center;
    color: #000000;
  }

  .link_to {
    display: block;
    width: 100%;
    border-radius: 5px;
    background-color: #f24462;

    a {
      display: block;
      padding: 18px 0 19px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.4px;
      text-align: center;
      color: #ffffff;
    }
  }

  // 주문 완료
  .order_complete_wrap {
    .status_title {
      margin-bottom: 18px;
    }

    .order_number {
      margin-bottom: 28px;
    }

    .deposit_info_wrap {
      padding-top: 24px;
      border-top: 1px solid #ececec;

      h4 {
        margin-bottom: 14px;
      }

      .transaction_info {
        padding: 21px 57px;
        border-radius: 2px;
        border: solid 1px #ececec;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.3px;
        color: #000000;

        > p {
          display: flex;
          justify-content: space-between;
        }

        .label {
          margin-right: 13px;
          font-weight: bold;
        }
      }
    }

    .order_message {
      padding: 41px 0 61px;

      strong {
        margin-right: 1px;
        color: #f24462;
      }
    }
  }

  // 결제 완료
  .payment_complete_wrap {
    .status_title {
      margin-bottom: 42px;
    }

    .order_number {
      margin-bottom: 11px;
    }

    .payment_message {
      margin-bottom: 83px;
    }
  }

  @media screen and (max-width: 767px) {
    .complete_wrap-diagonal1 {
      position: absolute;
      top: -30px;
      width: 144px;
      height: 131px;
    }

    .complete_wrap-diagonal2 {
      position: absolute;
      width: 43px;
      height: 49px;
      top: 60px;
    }

    .complete_wrap-diagonal3 {
      position: absolute;
      width: 106px;
      height: 95px;
      top: 100px;
    }

    .inner_wrap {
      width: 100%;
      padding: 44px 25px 137px;
    }

    // common
    .status_title {
      font-size: 18px;
      letter-spacing: -0.71px;
    }

    .order_number {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.55px;

      strong {
        margin-right: 7px;
      }
    }

    .message {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.55px;
    }

    .link_to {
      a {
        padding: 16px 0 14px;
        border-radius: 5px;
        font-size: 12px;
        letter-spacing: -0.34px;
      }
    }

    // 주문 완료
    .order_complete_wrap {
      .status_title {
        margin-bottom: 6px;
      }

      .order_number {
        margin-bottom: 33px;
      }

      .deposit_info_wrap {
        padding-top: 0;
        border-top: 0 none;

        h4 {
          display: none;
          margin-bottom: 14px;
        }

        .transaction_info {
          padding: 26px 26px;
          font-size: 14px;
          letter-spacing: -0.26px;

          > p {
            display: flex;
            justify-content: space-between;
          }

          .label {
            width: 60px;

            margin-right: 21px;
          }
        }
      }

      .order_message {
        padding: 24px 0 147px;
      }
    }

    // 결제 완료
    .payment_complete_wrap {
      .status_title {
        margin-bottom: 6px;
      }

      .order_number {
        margin-bottom: 18px;
      }

      .payment_message {
        margin-bottom: 72px;
      }
    }
  }
}
