.like__lectures__warp {
  padding-bottom: 137px;

  .inner__wrap {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .header__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    h3 {
      font-size: 20px;
      letter-spacing: -0.33px;
      color: #000000;
      margin-bottom: 23px;
    }

    .header__renew__btn {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.3rem;
      border: 1px solid #e5e5e5;
      border-radius: 0.3rem;
      padding: 0 0.5rem;
      margin: 0 30px 23px 0;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }

      &:active {
        transform: scale(0.95);
      }

      span {
        color: #949494;
        font-size: 0.875rem;
        line-height: 2.4;
      }

      .renew__btn__icon__wrap {
        width: 1.3rem;
        height: 1.3rem;
        margin-bottom: 0.15rem;
      }
    }

  }

  .lectures__content {
    .lecture__list {
      display: flex;
      flex-wrap: wrap;
      min-height: 380px;
      justify-content: space-between;
      row-gap: 1.5rem;

      .lecture_item {
        margin-bottom: 60px;
        margin-right: 30px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0 1rem 3rem;

    .header__wrap {
      display: none;
    }

    .lectures__content {
      .lecture__list {
        min-height: 260px;
        padding-bottom: 2rem;

        .lecture_item {
          margin-bottom: 0;
          margin-right: 0;
        }
      }
    }
  }
}

.empty__like__lectures__wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 80px;

  button {
    width: 9rem;
    border-radius: 0.25rem;
    border: 1px solid #F24462;
    color: #F24462;
    margin-top: 1rem;
    font-size: 14px;
    cursor: pointer;
    padding: 0.5rem;
  }
}