.lecture_row {
  td {
    padding: 18px 12px;
    width: 102px;
    text-align: center;
    letter-spacing: -0.82px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    border-bottom: 1px solid #ececec;
    box-sizing: border-box;
  }

  .lecture_due {
    width: 182px;

    span {
      white-space: nowrap;
    }
  }

  .lecture_common_btn {
    font-weight: bold;
    color: #f24462;

    span {
      display: inline-block;
      vertical-align: bottom;
      background-image: url('../../../../../Assets/Images/icon-dropdown-down.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 15px;
      height: 12px;
      cursor: pointer;
      filter: invert(63%) sepia(0%) saturate(2%) hue-rotate(68deg) brightness(101%) contrast(99%);
    }

    span[open] {
      transform: rotate(180deg);
    }
  }

  .lecture_short_btn {
    font-weight: bold;
    color: #f24462;

    span {
      display: inline-block;
      vertical-align: bottom;
      background-image: url('../../../../../Assets/Images/icon-dropdown-down.png');
      background-repeat: no-repeat;
      background-size: contain;
      width: 15px;
      height: 12px;
      cursor: pointer;
      filter: invert(63%) sepia(0%) saturate(2%) hue-rotate(68deg) brightness(101%) contrast(99%);
    }

    span[open] {
      transform: rotate(180deg);
    }
  }

  .lecture_review {
    .write-review-btn {
      color: #f24462;
      font-weight: bold;

      &:before {
        content: '';
        display: inline-block;
        vertical-align: bottom;
        background-image: url('../../../../../Assets/Images/review-write.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-right: 4px;
      }

      &.complete {
        color: #a2a2a2;
        font-weight: normal;

        &:before {
          cursor: default;
          background-image: url('../../../../../Assets/Images/review-complete.png');
        }
      }
    }
  }

  .lecture_certificate {
    span {
      &.pass {
        cursor: pointer;
      }

      &.fail,
      &.wait {
        cursor: default;
        pointer-events: none;
      }

      &.wait,
      &.pass {
        color: #f24462;
        font-weight: 700;
      }

      &.wait {
        opacity: 0.75;
      }
    }
  }
}

.lecture_row--online {
  .lecture_title {
    width: 326px;
  }
}

.lecture_row--offline {
  .lecture_title {
    width: 296px;
  }

  .lecture_remain_days {
    width: 132px;
  }
}

.lecture_row--pending {
}

.lecture_row--ongoing {
  .lecture_status {
    color: #f24462;
  }

  .lecture_short_btn {
    span {
      filter: invert(38%) sepia(100%) saturate(2718%) hue-rotate(326deg) brightness(99%) contrast(92%);
    }
  }
}

.lecture_row--end {
  td {
    color: #a2a2a2;
  }
}

.write-review-popup {
  margin-top: -72px;
  margin-bottom: -24px;
  letter-spacing: -0.5px;

  @media screen and (max-width: 767px) {
    height: 100%;
    margin-top: -16px;
    letter-spacing: -0.7px;
  }

  &-title {
    font-size: 20px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 12px;

    @media screen and (max-width: 767px) {
      width: 90%;
      padding-left: 12px;
      word-break: break-all;
      text-align: left;
    }
  }

  &-banner {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 28px;
    font-size: 0;
    background-color: #000000;
    margin-bottom: 16px;

    > img {
      display: inline-block;
    }

    > span {
      font-size: 13px;
      font-weight: 500;
      color: #ffffff;
      margin-left: 12px;
      display: inline-block;
      padding: 6px 0;
    }
  }

  &-error-alert {
    display: block;
    justify-content: center;
    max-width: 50%;
    margin: 0 auto;
    height: 40px;
    padding: 0 16px;
    font-size: 14px;
    background-color: #000000;
    margin-bottom: 8px;
    border-radius: 8px;
    color: white;
    line-height: 40px;
    transition: all 0.3s ease-in-out;

    @media screen and (max-width: 767px) {
      max-width: 80%;
    }

    &.hidden {
      height: 0;
      visibility: hidden;
    }
  }

  &-content {
    text-align: left;

    &-button {
      height: 60px;
      overflow: hidden;

      @media screen and (max-width: 767px) {
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translate(-50%, 0%);
        width: 90% !important;
      }
    }

    h4 {
      margin-bottom: 12px;
      padding: 0 16px;
      font-size: 15px;
    }

    div {
      * {
        font-size: 13px;
      }

      p {
        font-size: 14px;

        @media screen and (max-width: 767px) {
          font-size: 13px;
        }
      }
    }

    ul {
      display: flex;
      column-gap: 4px;
      margin-bottom: 4px;

      li {
        font-size: 0;
      }
    }

    textarea {
      border: 1px solid #bebebe;
      border-radius: 5px;
      width: 640px;
      min-height: 100px;
      padding: 12px;
      font-size: 12px;
      margin-top: 8px;

      @media screen and (max-width: 767px) {
        width: 100%;
      }

      &.error {
        border: 2px solid #f24462;
      }
    }

    &-form-fieldset {
      height: 580px;
      overflow-y: auto;
      padding: 0 16px;

      @media screen and (max-width: 767px) {
        width: 100%;
        height: 100%;
        max-height: 78vh;
      }
    }

    &-general {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 20px;

      h5 {
        font-size: 16px;
      }
    }

    &-detail {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      &-title {
        display: flex;
        column-gap: 10px;

        > img {
          width: 40px;
          height: 40px;
        }

        > p {
          font-size: 16px;
          font-weight: 400;

          br {
            @media screen and (max-width: 767px) {
              display: none;
            }
          }
        }
      }

      &-content,
      &-time,
      &-tutor {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .rating-star {
    width: 20px;
    aspect-ratio: 1;
    cursor: pointer;
  }

  .MuiRating-root {
    label {
      width: 20px;
      height: 20px;

      .MuiRating-iconFilled {
        svg {
          color: #fadf4a;

          @media screen and (max-width: 767px) {
            font-size: 16px;
          }
        }
      }

      .MuiRating-iconEmpty {
        svg {
          color: #ececec;
        }
      }
    }
  }
}

#popup-root {
  .mobile-popup-content.write-review-popup-content {
    @media screen and (max-width: 767px) {
      height: 98vh;
      padding: 40px 12px 12px 12px !important;

      .popup-close {
        right: 24px;
      }
    }
  }

  .write-review-popup-wrapper-content {
    max-height: 90vh;

    .popup-diagonal-left {
      top: 50px;
    }

    .popup-diagonal-right {
      &-top {
        top: unset;
        bottom: 360px;
      }

      &-bottom {
        top: unset;
        bottom: 240px;
      }
    }

    .popup-close {
      margin-top: -16px;
    }
  }
}
