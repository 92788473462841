.lectures_content {
  table {
    thead,
    tbody {
      display: block;

      max-height: 500px;
      overflow: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }

      tr {
        display: table;
        table-layout: fixed;
      }
    }
  }

  .title {
    margin-bottom: 23px;

    .permission_review {
      margin-left: 8px;
      font-size: 12px;
      vertical-align: middle;
      color: #fff;
      font-weight: 600;
      border-radius: 12px;
      background-color: #f24462;
      padding: 4px 12px;
    }
  }

  .lectures_tabs {
    display: flex;
    margin-bottom: 16px;

    li {
      position: relative;
      margin-right: 18px;
      padding: 0 4px 10px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.3px;
      text-align: center;
      color: #000000;
      cursor: pointer;

      &.isActive {
        color: #f24462;

        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          content: '';
          width: 100%;
          height: 3px;
          background-color: #f24462;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .lectures_subtabs {
    display: flex;
    margin-top: -23px;
    margin-bottom: 11px;
    height: 50px;
    background-color: #fafafa;

    li {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: bold;
      color: #a2a2a2;
    }

    li.isActive {
      color: #f24462;
    }
  }

  section {
    margin-top: 50px;

    h4 {
      margin-bottom: 14px;
      font-size: 19px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th {
    padding: 18px 12px;
    border-bottom: 1px solid #ececec;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.76px;
    color: #000000;
    text-align: center;
    box-sizing: border-box;
  }

  @media screen and (max-width: 767px) {
    .lectures_tabs {
      justify-content: flex-start;
      margin-bottom: 23px;
      padding: 0 24px;
      box-sizing: border-box;

      li {
        width: 33.33%;
        margin-right: 0;
        padding: 8px 0;
        font-size: 14px;
        letter-spacing: -0.26px;

        &.isActive {
          color: #f24462;

          &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 3px;
            background-color: #f24462;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .lecture_list {
      padding: 0 24px;
      box-sizing: border-box;

      .lecture_item {
        width: 100%;
        margin-bottom: 44px;
        padding: 25px 18px 21px;
        border-radius: 5px;
        box-shadow: 0 6px 10px 0 rgba(17, 17, 17, 0.1);
        box-sizing: border-box;

        .MuiTooltip-popper {
          div:nth-child(1) {
            margin-top: 5px;
          }
        }

        .tooltip_dropdown {
          width: 15px;
          padding: 0 0 0 5px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &_status {
          font-size: 13px;
          letter-spacing: -0.24px;
          color: #f24462;

          &.isEnded {
            color: #bebebe;
          }

          &.isPending {
            color: #000000;
          }
        }

        &_title {
          padding: 8px 3px;
          border-bottom: 1px solid #ececec;
          font-size: 14px;
          letter-spacing: -0.76px;
          color: #000000;

          &.isEnded {
            color: #bebebe;
          }
        }

        &_info {
          padding: 12px 0 20px;

          ul {
            li {
              display: flex;
              justify-content: space-between;
              margin-bottom: 4px;
              font-size: 12px;

              &:last-child {
                margin-bottom: 0;
              }

              strong {
                color: #bebebe;
              }

              span {
                letter-spacing: -0.43px;
                color: #000000;
              }
            }
          }
        }

        .go_to_classroom_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px 0;
          border-radius: 5px;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: -0.57px;
          color: #ffffff;
          background-color: #f24462;
        }

        .write_review_btn {
          border: 1px solid #f24462;
          width: 100%;
          border-radius: 5px;
          margin-top: 8px;
          color: #f24462;
          font-size: 12px;
          padding: 12px 0;
          line-height: 12px;

          &:before {
            content: '';
            display: inline-block;
            vertical-align: bottom;
            background-image: url('../../../Assets/Images/review-write.png');
            background-repeat: no-repeat;
            background-size: contain;
            width: 16px;
            height: 16px;
            cursor: pointer;
            margin-right: 4px;
          }

          &.has-review {
            border-color: #a2a2a2;
            color: #a2a2a2;

            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}

.swal-button.swal-button--oneday {
  background-color: #d8d8d8;

  &:hover {
    background-color: #f24462;
  }
}

.swal-text {
  font-size: 15px;
  letter-spacing: -0.24px;
}
