.Loading {
  height: 100%;
  width: 100%;
  font-family: Helvetica;

  $loader-width: 70px;
  $loader-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    display: flex;
    height: 100%;
    align-items: center;
    height: $loader-height;
    width: $loader-width;
    opacity: 0.6;
  }

  $dot-witdh: 10px;
  $dot-border: 1px;

  .loader--dot {
    animation-name: loader;
    animation-timing-function: ease-in-out;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    height: $dot-witdh;
    width: $dot-witdh;
    border-radius: 100%;
    background-color: black;
    position: absolute;
    border: $dot-border solid white;
  }

  .loader--dot:first-child {
    background-color: #8cc759;
    animation-delay: 0.5s;
  }

  .loader--dot:nth-child(2) {
    background-color: #8c6daf;
    animation-delay: 0.4s;
  }

  .loader--dot:nth-child(3) {
    background-color: #ef5d74;
    animation-delay: 0.3s;
  }

  .loader--dot:nth-child(4) {
    background-color: #f9a74b;
    animation-delay: 0.2s;
  }

  .loader--dot:nth-child(5) {
    background-color: #60beeb;
    animation-delay: 0.1s;
  }

  .loader--dot:nth-child(6) {
    background-color: #fbef5a;
    animation-delay: 0s;
  }

  .loader_text {
    display: flex;
    width: 100%;

    .loader_t-text {
      margin: auto;
      color: white;
    }

    .loader_t-text:after {
      content: 'Loading';
      font-weight: bold;
      animation-name: loading-text;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
  }

  @keyframes loader {
    5% {
      transform: translateX(0);
    }
    35% {
      transform: translateX($loader-width);
    }
    65% {
      transform: translateX($loader-width);
    }
    95% {
      transform: translateX(0);
    }
  }
  @keyframes loading-text {
    0% {
      content: 'Loading';
    }
    25% {
      content: 'Loading.';
    }
    50% {
      content: 'Loading..';
    }
    75% {
      content: 'Loading...';
    }
  }
}
