.header_wrap {
  padding: 22px 0 0;
  border-bottom: 1px solid #ececec;
  box-sizing: border-box;

  .nav_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    width: 169px;
    font-size: 0;

    a {
      display: block;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .locale-select {
    margin-left: auto;
    margin-right: 12px;
  }

  .search_control_wrap {
    width: 100%;
    position: fixed;
    left: 0;
    top: 76px;
    z-index: 10;
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-bottom: 1px solid #ececec;
    box-sizing: border-box;
    height: auto;
    min-height: 200px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    padding: 32px 0;
    flex-direction: column;
    items-align: center;

    .search_wrap_close_wrap {
      position: relative;
      width: 760px;
      height: 20px;
      margin: 0 auto;

      .search_wrap_close {
        display: block;
        padding: 4px 12px;
        width: fit-content;
        font-size: 14px;
        position: absolute;
        bottom: 0;
        right: 150px;
      }
    }

    .search_control_inner_wrap {
      padding: 30px 400px 30px 190px;
      width: 1200px;
      max-height: 400px;
      transition-delay: 0.1s;
      transition: height 0.3s ease-in-out;
      overflow: hidden !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 50px;
      margin: 0 auto;

      .divider {
        margin-top: auto;
        width: 100%;
        height: 1px;
        background-color: #bebebe;
      }

      .search_control_recent_words {
        &_title_wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 12px;

          button {
            font-size: 12px;
            color: #bebebe;
            margin-right: 12px;
          }
        }

        &_list {
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          font-size: 14px;
          color: #333;
          height: 130px;
          overflow-y: scroll;

          li {
            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
              font-size: 0;
              width: 8px;
              height: 8px;
              color: #bebebe;
              margin-right: 12px;
              background-image: url('../../../Assets/Images/icon-cancel-black.png');
              background-repeat: no-repeat;
              background-position: center;
              background-color: transparent;
              background-size: 14px;
              opacity: 0.3;
            }
          }
        }
      }

      .search_control_keyword_wrap {
        h3 {
          margin-bottom: 12px;
        }

        .search_control_keyword_list {
          display: flex;
          flex-direction: row;
          column-gap: 24px;
          font-size: 14px;

          button {
            padding: 4px 8px;
            border-radius: 4px;
            background-color: #ececec;
          }
        }
      }
    }

    &.hidden {
      border: none;
      transition: all 0.3s ease-in-out;
      height: 0;
      min-height: 0;
      padding: 0;
      overflow: hidden;

      > .search_control_inner_wrap {
        height: 0;
      }
    }
  }

  .header {
    &_top_nav {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      .search_wrap {
        width: 100%;
        max-width: 400px;
        margin-left: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: max-width 0.1s ease-in-out;

        .search_cancel_btn {
          font-size: 14px;
          display: block;
          width: 32px;
          opacity: 0;
        }

        &.active {
          max-width: 600px;
          transition: max-width 0.3s ease-in-out;

          .search_cancel_btn {
            opacity: 1;
            transition: opacity 0.1s ease-in-out;
            transition-delay: 0.3s;
            padding: 0 8px;
            min-width: 80px;
          }
        }

        @media screen and (max-width: 767px) {
          display: none;
        }

        .search_input_wrap {
          background: #ececec80;
          border-radius: 20px;
          overflow: hidden;
          width: 100%;
          height: 44px;
          display: flex;
          align-items: center;
          padding: 16px 16px 15px 16px;

          img {
            display: block;
          }

          input {
            width: 100%;
            background-image: url('../../../Assets/Images/ico-search@2x.png');
            background-repeat: no-repeat;
            background-size: 16px 16px;
            background-position-x: 0;
            background-position-y: 2px;
            background-color: transparent;
            border: none;
            font-size: 14px;
            outline: none;
            padding-left: 20px;
          }
        }
      }

      .user_nav {
        display: flex;
        font-size: 12px;

        > li {
          position: relative;
          padding: 0 17px;
          line-height: 1;
          letter-spacing: -0.23px;
          cursor: pointer;

          &:last-child {
            padding-right: 0;
          }

          &::before {
            position: absolute;
            top: 50%;
            right: 0;
            content: '|';
            transform: translateY(-50%);
          }

          &:hover {
            font-weight: 500;
          }

          &.my_page_nav {
            &:hover {
              .my_page_options {
                display: block;
              }
            }
          }

          &:last-child {
            &::before {
              display: none;
            }
          }
        }
      }

      .logged_in_nav {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.23px;
        color: #000000;
        cursor: pointer;

        .user_profile {
          width: 28px;
          height: 28px;
          margin-right: 22px;
          border-radius: 50%;
        }

        .user_message {
          display: flex;
          align-items: center;
          max-width: 240px;

          img {
            width: 7px;
            margin-left: 16px;
          }
        }

        .user_drop_down_menu {
          position: absolute;
          top: 39px;
          right: 0;
          width: 165px;
          padding: 12px 19px;
          border-radius: 5px;
          border: 1px solid #e5e5e5;
          background-color: #ffffff;
          box-sizing: border-box;
          z-index: 11;

          li {
            padding: 10px 0;
            border-bottom: 1px solid #ececec;
            text-align: center;

            a {
              display: block;
              max-height: 18px;
              line-height: 1.75;
            }

            &:last-child {
              border-bottom: 0 none;
            }
          }

          .notice__list {
            a {
              position: relative;
            }

            span {
              position: absolute;
            }
          }

          .recent-board-count {
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-left: 5px;
            border-radius: 50%;
            background-color: rgba(230, 230, 230, 0.75);
          }
        }
      }

      .my_page_options {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 20px;
        white-space: nowrap;
        z-index: 2;

        ul {
          border-radius: 4px;
          border: 1px solid #e4e4e4;
          font-weight: normal;
          background: #ffffff;

          li {
            padding: 4px 10px;

            &:hover {
              font-weight: 500;
            }
          }
        }
      }

      .user_mobile_nav_btn {
        display: none;
      }
    }

    &_bottom_nav {
      display: flex;
      flex-direction: row;
      column-gap: 1.5rem;

      a {
        font-size: 16px;
        font-weight: bold;
      }

      .instructor_link {
        min-width: 100px;
      }

      ul.global_nav_wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .global_nav {
          display: flex;
          height: 54px;

          /* 드롭다운 메뉴 스타일 */
          .dropdown__menu .dropdown-list__menu {
            display: none;
            position: absolute;
            z-index: 1;
            width: max-content;
            margin-top: 0.15625rem;
            border: 1px solid #ccc;
            padding: 5px 0;
            border-radius: 5px;
          }

          .dropdown__menu:hover .dropdown-list__menu {
            display: block;
            background-color: #fff;
            pointer-events: auto;

            .dropdown-list {
              pointer-events: auto;
            }
          }

          .dropdown-list {
            pointer-events: none;
            padding: 0 0.5rem;
            margin: 0;
            height: 2.5rem;
            transition: background-color 0.2s ease-in-out;

            &:hover {
              border: none !important;
            }

            & a {
              justify-content: flex-start;
              padding: 0.375rem 0.5rem;
              font-weight: normal;
              align-items: normal;
              height: 2.5rem !important;
              line-height: 1.9rem;
              transition: background-color 0.2s ease-in-out;
            }

            & a:hover {
              border-radius: 5px;
              background-color: rgba(166, 166, 166, 0.1);
              transition: background-color 0.2s ease-in-out;
            }
          }

          // 모바일 드롭다운 대응
          @media screen and (max-width: 767px) {
            .dropdown-list__menu.mobile {
              left: 0;
              margin-top: 0;
              position: fixed;
              top: 7.2rem;
              width: 100vw;
              padding: 0.375rem 1rem;
              border: 0;
              border-top: 1px solid #ececec;
              border-radius: 0 0 5px 5px;
              box-shadow: 0 70px 80px 0 rgba(0, 0, 0, 0.25);

              .dropdown-list {
                margin: 0px !important;
                padding: 1px 0 !important;
              }
            }
          }
          /* 드롭다운 메뉴 스타일 종료 */

          li {
            position: relative;
            margin-right: 85px;
            letter-spacing: -0.3px;
            box-sizing: border-box;
            padding-bottom: 2px;

            a {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
            }

            &.isActive {
              padding-bottom: 0;
              border-bottom: 2px solid black;

              &.isGov {
                border-bottom: 2px solid #f24462;
              }
            }

            &:hover {
              &:not(.isActive) {
                padding-bottom: 0px;
                border-bottom: 2px solid black;
              }

              &.isGov:not(.isActive) {
                padding-bottom: 0px;
                border-bottom: 2px solid #f24462;
              }
            }
          }

          &.gov {
            li {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .mobile_user_nav_modal {
    display: none;
  }

  @media screen and (max-width: 767px) {
    height: auto;
    padding: 0;
    border-bottom: 0 none;

    &.isFixed {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      background: #ffffff;
      z-index: 8;
    }

    .inner_wrap {
      padding: 0;
    }

    .logo {
      width: 114px;
    }

    .header {
      &_top_nav {
        margin: 0;
        padding: 15px 24px 11px;
        border-bottom: 1px solid #ececec;

        .user_nav {
          display: none;
        }

        .logged_in_nav {
          display: none;
        }

        .user_mobile_nav_btn {
          display: block;
          width: 14px;
          cursor: pointer;

          img {
            width: 100%;
          }
        }
      }

      &_bottom_nav {
        padding: 0 24px;
        overflow-x: scroll;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-height: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none; /* Chrome, Safari, Opera*/
        }

        a {
          font-weight: normal;
        }

        ul.global_nav_wrap {
          #gov {
            display: none;
          }

          .global_nav {
            flex: 1;

            li {
              min-width: 15%;
              white-space: nowrap;
              margin-right: 8px;
              padding: 13px 8px 2px;
              margin-bottom: 7px;
              font-size: 13px;
              letter-spacing: -0.56px;
              text-align: center;
              cursor: pointer;

              &:first-child {
                margin-left: 0;
                padding-left: 0;
              }

              &.isActive {
                &::before {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  height: 2px;
                  background-color: #000000;
                }
              }

              &:focus {
                &:not(.isActive) {
                  &::before {
                    display: none;
                  }
                }
              }

              &:hover {
                &:not(.isActive) {
                  border: none;
                  padding-bottom: 2px;
                }
              }

              &:last-child {
                margin-right: 4rem;
              }
            }
          }
        }

        .instructor_link {
          display: none;
        }
      }
    }
  }
}

.dimmed {
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;

  &.hidden {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
}
