.lecture_item {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: start;
  row-gap: 2px;
  width: 276px;

  .lecture_like {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    z-index: 1;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }

  .lecture_item_link_wrap {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &_link_wrap {
    &:hover {
      .lecture_item_title {
        color: #f24462;
      }

      .badge-wrap > span.listen_type {
        color: #979797;
        border-color: #979797;
      }
    }
  }

  .thumbnail_wrap {
    position: relative;
    width: 100%;
    height: 208px;
    border-radius: 4px;
    overflow: hidden;

    a {
      display: block;
      height: 100%;
    }

    .thumbnail {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .appointment {
      position: absolute;
      top: 12px;
      left: 12px;
      padding: 7px 15px;
      border-radius: 5px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.2px;
      color: #ffffff;
      background-color: #f24462;
      cursor: pointer;
    }
  }

  .lecture_item_title {
    position: relative;
    font-size: 14px;
    letter-spacing: -0.25px;
    word-break: break-word;
    white-space: pre-wrap;
    color: #000000;

    &.ellipsis {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .badge-wrap {
    display: flex;
    align-items: center;
    gap: 4px;
    min-height: 22px;
    height: fit-content;
    padding: 0;

    > span {
      padding: 1px 10px 0;
      border-radius: 9.5px;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: -0.36px;
      text-align: center;
      height: 22px;
      line-height: 22px;

      &.discount {
        color: #fff;
        background-color: #f3213c;
      }

      &.deadline {
        padding: 1px 8px 0 6px;
        color: #fff;
        background-color: #000;
        display: flex;
        align-items: center;
        gap: 4px;

        > img {
          width: 12px;
          margin-top: -2px;
        }
      }

      &.waiting {
        padding: 1px 8px 0 6px;
        color: #fff;
        background-color: #606060;
        display: flex;
        align-items: center;
        gap: 4px;

        > img {
          width: 12px;
          margin-top: -2px;
        }
      }

      &.listen_type {
        border: 1px solid #bebebe;
        color: #bebebe;
        text-transform: capitalize;
      }
    }
  }

  .lecture_item_rate_wrap {
    display: flex;
    font-size: 13px;
    height: 22px;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    .lecture_item_rate {
      display: flex;
      align-items: center;

      > span {
        line-height: 22px;
        height: 22px;
        padding-top: 1px;
        margin-left: 4px;

        + span {
          margin-left: 6px;
          color: #a2a2a2;
        }
      }
    }

    .lecture_selling_count {
      height: 22px;
      display: flex;
      align-items: center;
      line-height: 22px;
    }
  }

  .lecture_item_description {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    letter-spacing: -0.34px;
    color: #a2a2a2;

    .lecture_item_category {
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;

      li {
        margin-bottom: 7px;
        margin-right: 10px;
        padding: 3px 16px;
        border-radius: 10px;
        font-size: 11px;
        letter-spacing: -0.4px;
        text-align: center;
        color: #f24462;
        background-color: rgba(255, 101, 121, 0.07);

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &.isRow {
      flex-direction: column;

      .lecture_item_category {
        margin-top: 7px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    $item-with: calc(((100vw - 48px) / 2) - 6px);
    width: $item-with;

    .lecture_like {
      top: 0.25rem;
      right: 0.25rem;
      width: var(--lectureMobileLikeWh, 1.5rem);
      height: var(--lectureMobileLikeWh, 1.5rem);
    }

    .thumbnail_wrap {
      width: 100%;
      height: calc(#{$item-with} * 0.75);

      .appointment {
        top: 8px;
        left: 8px;
        padding: 4px 10px;
        border-radius: 5px;
        font-size: 9px;
        letter-spacing: -0.4px;
      }
    }

    .lecture_item_title {
      letter-spacing: -0.55px;
    }

    .badge-wrap {
      padding: 4px 0 1px;

      > span {
        padding: 0 9px;
      }
    }

    .lecture_item_rate_wrap {
      height: 14px;
      font-size: 11px;

      .lecture_item_rate {
        > span {
          svg {
            width: 14px;
            height: 14px;
          }

          + span {
            line-height: 18px;
          }
        }
      }
    }

    .lecture_item_description {
      font-size: 11px;
      letter-spacing: -0.41px;

      .lecture_item_category {
        li {
          margin-bottom: 7px;
          margin-right: 6px;
          padding: 3px 14px;
          font-size: 9px;
          letter-spacing: -0.5px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &.isRow {
        flex-direction: column;

        .lecture_item_category {
          margin-top: 7px;
        }
      }
    }
  }
}
