.room-wrapper {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.room {
  width: 100%;
  height: 100%;
  display: flex;

  .mobile {
    display: none;
  }

  section {
    height: 100%;

    &:first-of-type {
      flex: 1;
      z-index: 1;
    }

    &:last-of-type {
      width: 400px;
      padding: 26px 43px;
      box-shadow: 0 6px 10px 0 rgba(17, 17, 17, 0.1);
      box-sizing: border-box;
      overflow: scroll;

      & > * {
        margin-bottom: 24px;
      }
    }
  }

  .room__header {
    height: 52px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid #ececec;
  }

  .room__back-btn {
    width: 52px;
    height: 100%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 12px;
      height: 12px;
      vertical-align: bottom;
      display: inline-block;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .room__header__title {
    margin-left: 12px;
    font-size: 14px;
    font-weight: bold;
  }

  .room__infos {
    margin-left: auto;
    display: flex;
    align-items: center;

    hr {
      margin: 0 15px;
      width: 1px;
      height: 11px;
      background-color: black;
      border: none;
    }
  }

  .room__info {
    font-size: 14px;
    font-weight: 500;
    white-space: pre;
  }

  .room__survey {
    height: 100%;
    overflow: auto;
    position: relative;
    padding-top: calc(720 / 1280 * 100%);

    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
    }
  }

  .room__player {
    position: relative;
    margin-top: 30px;
    padding-top: calc(720 / 1280 * 100%);

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }

    video {
      outline: none;
    }
  }

  .room__file {
  }

  .room__file__material {
    padding: 16px 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    border-radius: 5px;
    background-color: #f8f8f8;
    cursor: pointer;

    img {
      margin-left: auto;
      height: 15px;
    }

    &:hover > * {
      opacity: 0.5;
    }
  }

  .room__list {
  }

  .room__file__title,
  .room__list__title {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
  }

  .room__chapters {
    margin-bottom: 15px;
  }

  .room__chapter {
    padding: 12px 13px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    background-color: #f8f8f8;
    cursor: pointer;
    user-select: none;
    margin-top: 6px;

    &:hover > * {
      opacity: 0.5;
    }
  }

  .room__chapter__number {
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background-color: white;
    border-radius: 14px;
  }

  .room__chapter__title {
    margin-left: 9px;
    font-size: 13px;
  }

  .room__chapter--disabled {
    background-color: darkgrey;
    cursor: default;
    opacity: 0.5;

    &:hover > * {
      opacity: 1;
    }
  }

  .room__chapter__fold {
    margin-left: auto;
    font-size: 9px;

    img {
      width: 7px;
    }
  }

  .room__chapter__button--done {
    font-weight: 500;
    color: #a2a2a2;
    pointer-events: none;
  }

  .room__chapter__button--current {
    font-weight: bold;
    color: #f24462;
    pointer-events: none;
  }

  .room__chapter__button--disabled {
    background-color: darkgrey;
    pointer-events: none;
    opacity: 0.5;
  }

  .room__sections {
    margin: 17px 0 24px 0;
    padding-left: 23px;
  }

  .room__section {
    display: flex;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  .room__section--done {
    font-weight: 500;
    color: #a2a2a2;
  }

  .room__section--current {
    font-weight: bold;
    color: #f24462;
    pointer-events: none;
  }

  .room__section--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .room__section + .room__section {
    margin-top: 11px;
  }

  .room__section__title {
  }

  .room__section__length {
    margin-left: auto;
    font-weight: 500;
    color: #a2a2a2;
  }
}

@media screen and (max-width: 767px) {
  .room {
    flex-direction: column;

    .pc {
      display: none;
    }

    .mobile {
      display: block;
    }

    .room__header {
      padding-right: 0;
    }

    .room__header__title {
      margin-left: 0;
      flex: 1;
      text-align: center;
      white-space: pre;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .room__mobile__title {
      margin-bottom: 9px;
      font-size: 14px;
      font-weight: bold;
      white-space: pre;
    }

    .room__infos {
      display: none;
    }

    .mobile .room__infos {
      display: block;

      hr {
        visibility: hidden;
        height: 3px;
      }
    }

    .room__info {
      font-size: 11px;
    }

    .room__info + .room__info {
      margin-top: 3px;
    }

    .room__menus {
      display: flex;
      align-items: center;
      height: 100%;
    }

    .room__menus__button--container {
      width: 52px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .room__menus__list{
      position: fixed;
      z-index: 1000;
      top: 52px;
      left: 0;
      width: 100%;
      height: calc(70vh - 52px);
      display: flex;
      flex-direction: column;
      background-color: white;

      section {
        height: 100%;
        width: 100%;

        &:first-of-type {
          padding: 21px 23px;
          border-bottom: 1px solid #ececec;
        }

        &:nth-of-type(2) {
          padding: 34px 23px;
          box-shadow: 0 6px 10px 0 rgba(17, 17, 17, 0.3);
          overflow: auto;
        }
      }
    }

    .room__survey {
      height: 100%;
    }

    .room__player {
      margin-top: 22px;
    }

    .room__file__title,
    .room__list__title {
      margin-bottom: 13px;
      font-size: 16px;
    }
  }
}
