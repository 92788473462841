.my_page_nav_wrap {
  user-select: none;
  max-width: 260px;

  .user_info {
    padding-bottom: 36px;
    border-bottom: 1px solid #ececec;

    h3 {
      font-size: 20px;
      letter-spacing: -0.33px;
      color: #000000;
    }

    .user_coupon {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.4px;
      color: #a2a2a2;
    }
  }

  .my_page_nav {
    padding-top: 40px;

    li {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.67px;
      color: #a2a2a2;

      &.isActive {
        font-weight: bold;
        color: #000000;
        pointer-events: none;
      }

      &.disabled {
        color: #e0e0e0;
        pointer-events: none;
      }
    }

    li.list-notice {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        margin-bottom: 3px;
      }
    }

    .recent-board-count {
      display: inline-block;
      width: 22px;
      height: 22px;
      margin-left: 10px;
      line-height: 26px;
      text-align: center;
      font-size: 0.9rem;
      border-radius: 50%;
      background-color: rgba(230, 230, 230, 0.75);
    }
  }

  // TODO: 기획 2.10 업데이트 이후 작업
  @media screen and (max-width: 767px) {
    display: none;
  }

  h4 {
    padding-top: 40px;
    color: #a2a2a2;

    &.isActive {
      color: #000000;
      pointer-events: none;
    }

    + .my_page_nav {
      padding-top: 24px;
    }

    &.mypage-title {
      text-transform: uppercase;
      cursor: default;
    }
  }

  #select-org {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .org_menu_header {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > h4 {
      padding: 0;
      margin-bottom: -4px;
    }

    + .my_page_nav {
      padding-top: 24px;
    }

    #org-select-label {
      background: #ffffff;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 0.875rem;

      &.MuiInputLabel-outlined {
        top: -8px;

        &.MuiInputLabel-shrink {
          top: 0;
          left: -8px;
          //transform: matrix(1, 0, 0, 1, 0, 0);
        }
      }
    }
  }
}
