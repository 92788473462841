@font-face {
  font-family: 'NotoSansCJKkr';
  src: local('*'), url('./Assets/font/NotoSansCJKkr-Light.woff') format('woff'),
    url('./Assets/font/NotoSansCJKkr-Light.ttf') format('truetype'),
    url('./Assets/font/NotoSansCJKkr-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'NotoSansCJKkr';
  src: local('*'), url('./Assets/font/NotoSansCJKkr-Medium.woff') format('woff'),
    url('./Assets/font/NotoSansCJKkr-Medium.ttf') format('truetype'),
    url('./Assets/font/NotoSansCJKkr-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'NotoSansCJKkr';
  src: local('*'), url('./Assets/font/NotoSansCJKkr-Medium.woff') format('woff'),
    url('./Assets/font/NotoSansCJKkr-Medium.ttf') format('truetype'),
    url('./Assets/font/NotoSansCJKkr-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'NotoSansCJKkr';
  src: local('*'), url('./Assets/font/NotoSansCJKkr-Bold.woff') format('woff'),
    url('./Assets/font/NotoSansCJKkr-Bold.ttf') format('truetype'),
    url('./Assets/font/NotoSansCJKkr-Bold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'NotoSansCJKkr';
  src: local('*'), url('./Assets/font/NotoSansCJKkr-Bold.woff') format('woff'),
    url('./Assets/font/NotoSansCJKkr-Bold.ttf') format('truetype'),
    url('./Assets/font/NotoSansCJKkr-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'NotoSansCJKkr';
  src: local('*'), url('./Assets/font/NotoSansCJKkr-Bold.woff') format('woff'),
    url('./Assets/font/NotoSansCJKkr-Bold.ttf') format('truetype'),
    url('./Assets/font/NotoSansCJKkr-Bold.otf') format('opentype');
  font-weight: bold;
}

body {
  margin: 0;
  font-family: 'NotoSansCJKkr', 'Noto Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
li,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}

input,
textarea {
  outline: 0 none;
  box-sizing: border-box;
}

textarea {
  resize: none; /* 사용자 임의 변경 불가 */
}

a {
  color: inherit;
  text-decoration: none;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// PC Common
.content_wrap {
  @media screen and (max-width: 767px) {
    &.hasPadding {
      // padding-top: 96.8px;
    }
  }
}

.inner_wrap {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (max-width: 767px) {
    width: 100%;
    min-width: 320px;
    padding: 0 20px;
  }
}

.my_page_content_wrap {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  min-height: calc(100vh - 450px);
  margin: 0 auto;
  padding: 100px 0 80px;
  column-gap: 20px;

  .my_page_content {
    width: 960px;

    .title {
      font-size: 20px;
      letter-spacing: -0.33px;
      color: #000000;
    }
  }

  .none_list {
    padding: 80px 0;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.76px;
    color: #acacac;
    text-align: center;
    box-sizing: border-box;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    min-width: 320px;
    padding-top: 22px;

    .my_page_content {
      width: 100%;

      .title {
        display: none;
      }
    }
  }
}

.modal_outside {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(50, 50, 50, 0.21);
  z-index: 999;
}

.mobile_header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 46px;
  padding: 0 21px;
  border-bottom: 1px solid #ececec;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.51px;
  color: #000000;
  box-sizing: border-box;

  img {
    position: absolute;
    top: 50%;
    left: 21px;
    width: 9px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
