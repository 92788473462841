.filter_select_box_options {
  position: absolute;
  top: 42px;
  left: 0;
  width: 200px;
  border-radius: 5px;
  border: solid 1px #e5e5e5;
  background-color: #ffffff;
  z-index: 2;

  &_search {
    height: 40px;
    border-bottom: 1px solid #dbdbdb;
    text-align: right;
    padding: 0 2px;

    img {
      width: 24px;
      display: inline-block;
      margin-bottom: -8px;
    }

    input {
      width: 85%;
      height: 80%;
      margin: 4px 0;
      border: none;
    }
  }

  &_list_wrap {
    max-height: 400px;
    overflow-y: scroll;

    .filter_select_box_options_list {
      li {
        padding: 16px 4px;
        font-size: 14px;
        letter-spacing: -0.5px;
        text-align: center;
        color: #000;
        cursor: pointer;
        height: 36px;

        img {
          display: inline-block;
          margin-right: 8px;
          height: 20px;
        }

        div {
          display: inline-block;
          text-align: left;
          word-break: break-all;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 80%;
          height: 20px;
        }
      }
    }
  }
}
