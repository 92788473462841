.locale-select {
  .MuiSelect-select {
    padding-right: 36px !important;
  }

  .MuiSelect-select::before {
    display: inline-block;
    vertical-align: middle;
    content: '';
    margin-right: 14px;
    width: 14px;
    height: 14px;
    background-image: url('../../../Assets/Images/planet-earth.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  fieldset {
    border-color: #ececec !important;
    border-width: 1px !important;
    outline: none;
  }
}
