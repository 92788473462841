.review_item {
  padding-bottom: 26px;
  border-bottom: 1px solid #ececec;

  .review_info {
    display: flex;
    align-items: center;

    .user_profile_image {
      width: 45px;
      height: 45px;
      margin-right: 9px;
      border-radius: 50%;
    }

    .review_user_info {
      position: relative;
      flex: 1;

      .best_badge {
        font-size: 11px;
        font-weight: bold;
        letter-spacing: -0.18px;
        color: #f24462;
      }

      .user_name {
        display: inline-block;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: -0.18px;
        color: #000;
      }

      .lecture_info {
        font-size: 11px;
        font-weight: 500;
        letter-spacing: -0.17px;
        color: #bebebe;
      }

      .review_date {
        font-size: 10px;
        letter-spacing: -0.17px;
        color: #000;
        margin-left: 0.25rem;
      }

      .review_start {
        display: flex;
        align-items: center;
        height: 11px;

        img {
          width: 11px;
          height: 11px;
          margin-right: 1px;
        }
      }

      .more_btn {
        position: absolute;
        top: 0;
        right: 22px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: -0.43px;
        color: #000;
        transform: translate(50%, 50%);
        cursor: pointer;

        img {
          width: 7px;
          margin-left: 7px;
        }
      }
    }
  }

  .review_description {
    height: auto;
    padding-top: 16px;
    padding-left: 54px;
    word-break: break-all;
    overflow: hidden;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: #000000;

    .ellipsis {
      height: auto;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      white-space: pre-wrap;
    }

    .origin_text {
      height: auto;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      white-space: pre-wrap;
    }
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 12px;

    .review_description {
      padding: 18px 15px 11px;
      font-size: 12px;
      letter-spacing: -0.43px;
    }
  }
}
