.bottom_event_banner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 47px;
  color: #ffffff;
  background-color: #000000;
  z-index: 9;

  a {
    display: block;
  }

  &.isFixed {
    position: fixed;
  }

  .inner_wrap {
    display: flex;
    align-items: center;
    height: 47px;
    padding: 0;
    overflow: hidden;

    strong {
      margin-right: 62px;
    }

    .slick-slider {
      width: 100%;

      .slick-slide {
        * {
          height: 47px !important;
          line-height: 47px;
        }
      }
    }

    .slick-vertical,
    .slick-list {
      height: 47px !important;
    }
  }

  @media screen and (max-width: 767px) {
    height: 47px;
    padding: 0 15px;
    overflow: hidden;
    box-sizing: border-box;

    .inner_wrap {
      height: 47px;

      .slick-slider {
        width: calc(100% - 80px);
      }

      strong {
        margin-right: 21px;
      }
    }
  }
}

.lectures_warp {
  padding: 42px 0 137px;

  h2 {
    margin-bottom: 16px;
    font-size: 24px;
    letter-spacing: -0.4px;
    color: #000000;
    font-weight: bold;

    img {
      margin-left: 10px;
      width: 25px;
      display: inline-block;
      margin-top: -4px;
    }
  }

  .search_lectures_wrap {
    .lectures_categories_wrap {
      ul {
        display: flex;

        li {
          box-sizing: border-box;

          &:last-child {
            margin: 0;
          }
        }
      }

      .lectures_categories {
        padding: 15px 0;
        border-top: 1px solid #f3f3f3;
      }

      .top_categories {
        ul {
          li {
            display: flex;
            align-items: center;
            margin-right: 32px;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.25px;
            color: #bebebe;
            cursor: pointer;

            &.isActive {
              color: #000000;
              cursor: default;

              &:hover {
                color: inherit;
              }
            }

            &:last-child {
              margin-right: 0;
            }

            &:hover {
              //color: #505050;
              color: #f22672;
            }
          }
        }
      }

      .middle_categories {
        &.isBorderBottom {
          border-bottom: 1px solid #f3f3f3;
        }

        ul {
          li {
            margin-right: 16px;
            padding: 8px 31px;
            border-radius: 5px;
            font-size: 12px;
            letter-spacing: -0.2px;
            color: #000000;
            background-color: rgba(178, 178, 178, 0.06);
            cursor: pointer;

            &.isActive {
              font-weight: 500;
              color: #ffffff;
              background-color: #f24462;
              cursor: default;
            }

            &:hover {
              border: 1px solid #f24462;
              padding: 7px 30px;
            }
          }
        }
      }

      .selected_options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0 11px;
        border-bottom: 1px solid #f3f3f3;

        ul {
          flex: 1;
          flex-wrap: wrap;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 16px;
            margin-bottom: 4px;
            padding: 9px 10px 8px 21px;
            border-radius: 5px;
            font-size: 12px;
            letter-spacing: -0.2px;
            color: #ffffff;
            background-color: #f24462;
            box-sizing: border-box;

            .delete_option_btn {
              width: 10px;
              margin-left: 21px;
              cursor: pointer;

              img {
                width: 100%;
              }
            }
          }
        }

        .reset_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 110px;
          margin-bottom: 4px;
          padding: 10px 0;
          border-radius: 5px;
          border: 1px solid #e5e5e5;
          font-size: 14px;
          letter-spacing: -0.5px;
          text-align: center;
          color: #000000;
          cursor: pointer;
          box-sizing: border-box;

          img {
            width: 10px;
            margin-left: 12px;
          }

          &:hover {
            border: 1px solid #f24462;
          }
        }
      }
    }
  }

  .lectures_content {
    .search_lectures_wrap {
      .lectures_categories_wrap {
        ul {
          display: flex;

          li {
            box-sizing: border-box;

            &:last-child {
              margin: 0;
            }
          }
        }

        .lectures_categories {
          padding: 15px 0;
          border-top: 1px solid #f3f3f3;
        }

        .top_categories {
          ul {
            li {
              margin-right: 32px;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: -0.25px;
              color: #bebebe;
              cursor: pointer;

              &.isActive {
                color: #000000;
              }
            }
          }
        }

        .middle_categories {
          &.isBorderBottom {
            border-bottom: 1px solid #f3f3f3;
          }

          ul {
            li {
              margin-right: 16px;
              padding: 7px 31px;
              border-radius: 5px;
              font-size: 12px;
              letter-spacing: -0.2px;
              color: #000000;
              background-color: rgba(178, 178, 178, 0.06);
              cursor: pointer;

              &.isActive {
                font-weight: 500;
                color: #ffffff;
                background-color: #f24462;
              }
            }
          }
        }
      }
    }

    .sort_select_box_wrap {
      padding: 16px 0;
      text-align: right;

      .sort_selected_option {
        position: relative;
        display: inline-block;
        min-width: 97px;
        padding: 10px 22px;
        border-radius: 5px;
        border: solid 1px #e5e5e5;
        font-size: 14px;
        letter-spacing: -0.5px;
        text-align: center;
        color: #000000;
        cursor: pointer;
        box-sizing: border-box;

        &:hover {
          border: solid 1px #f24462;
        }

        &.foreignMinWidth {
          min-width: 185px;
        }

        span {
          display: flex;
          justify-content: space-between;
          align-items: center;

          img {
            width: 7px;
          }
        }
      }

      .sort_select_box_options {
        position: absolute;
        top: 42px;
        right: 0;
        width: 100%;
        border-radius: 5px;
        border: solid 1px #e5e5e5;
        background-color: #ffffff;
        z-index: 2;

        li {
          padding: 6px 0;
          font-size: 14px;
          letter-spacing: -0.5px;
          text-align: center;
          color: #000;
          cursor: pointer;

          &:hover {
            color: #f24462;
          }
        }
      }
    }

    .lecture_list_wrap {
      display: flex;
      flex-wrap: wrap;
      min-height: 380px;
      justify-content: space-between;
      row-gap: 1.5rem;

      .lecture_item {
        margin-bottom: 60px;
        margin-right: 30px;

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0 0 80px;

    h2 {
      display: none;
    }

    .search_lectures_wrap {
      .lectures_categories_wrap {
        .top_categories {
          padding: 18px 0;
          border: 0 none;

          ul {
            li {
              margin-right: 7px;
              padding: 8px 18px;
              border-radius: 5px;
              border: solid 1px #ececec;
              font-size: 11px;
              letter-spacing: -0.5px;
              color: #000000;

              img {
                width: 7px;
              }
            }
          }
        }

        .middle_categories {
          display: none;
        }

        .selected_options {
          padding-top: 0;
          padding-bottom: 5px;
          border-top: 0 none;

          ul {
            li {
              margin-right: 7px;
              margin-bottom: 6px;
              padding: 8px 15px;
              font-size: 11px;
              font-weight: 500;
              letter-spacing: -0.4px;
              color: #ffffff;

              .delete_option_btn {
                width: 7px;

                img {
                  width: 100%;
                }
              }
            }
          }

          .reset_btn {
            width: 74px;
            padding: 7px 15px;
            font-size: 11px;
            letter-spacing: -0.4px;
            color: #a2a2a2;

            img {
              margin-top: -1px;
              margin-left: 4px;
            }
          }
        }
      }
    }

    .lectures_content {
      .search_lectures_wrap {
        .lectures_categories_wrap {
          ul {
            display: flex;

            li {
              box-sizing: border-box;

              &:last-child {
                margin: 0;
              }
            }
          }

          .lectures_categories {
            padding: 15px 0;
            border-top: 1px solid #f3f3f3;
          }

          .top_categories {
            ul {
              li {
                margin-right: 32px;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: -0.25px;
                color: #bebebe;
                cursor: pointer;

                &.isActive {
                  color: #000000;
                }
              }
            }
          }

          .middle_categories {
            &.isBorderBottom {
              border-bottom: 1px solid #f3f3f3;
            }

            ul {
              li {
                margin-right: 16px;
                padding: 7px 31px;
                border-radius: 5px;
                font-size: 12px;
                letter-spacing: -0.2px;
                color: #000000;
                background-color: rgba(178, 178, 178, 0.06);
                cursor: pointer;

                &.isActive {
                  font-weight: 500;
                  color: #ffffff;
                  background-color: #f24462;
                }
              }
            }
          }
        }
      }

      .sort_select_box_wrap {
        padding: 15px 0 6px;

        .sort_selected_option {
          min-width: 74px;
          padding: 5px 16px;
          font-size: 11px;
          letter-spacing: -0.39px;

          &.foreignMinWidth {
            min-width: 146px;
          }
        }

        .sort_select_box_options {
          top: 30px;

          li {
            font-size: 11px;
            letter-spacing: -0.39px;
          }
        }
      }

      .lecture_list_wrap {
        min-height: 260px;

        .lecture_item {
          margin-bottom: 0;
          margin-right: 0;
        }
      }
    }
  }
}

.empty-lectures-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 80px;
}
