.lecture_detail_wrap {
  padding: 52px 0 0;

  .inner_wrap {
    position: relative;

    .inner_content_wrap {
      display: flex;
    }

    .info_wrap {
      position: relative;
      float: left;
      box-sizing: border-box;
    }
  }

  .intro_title,
  .review_title,
  .video_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1.5;
    font-size: 20px;
    letter-spacing: -0.33px;
    color: #000000;
    white-space: pre-wrap;

    img {
      margin-right: 17px;
    }
  }

  a {
    color: #306fd8;
    text-decoration: underline;

    &:hover {
      color: #212eac;
    }

    &:visited {
      color: purple;
    }
  }

  .lecture_detail_info {
    width: 786px;
    padding-bottom: 180px;

    .lecture_title_image_wrap {
      position: relative;
      margin-bottom: 16px;
      border-radius: 5px;
      overflow: hidden;

      .lecture_title_image {
        width: 100%;
        object-fit: cover;
      }

      // TODO: 모바일 피드백 이후 조절
      .appointment {
        position: absolute;
        top: 21px;
        left: 21px;
        padding: 12px 31px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.23px;
        color: #ffffff;
        background-color: #f24462;
      }
    }

    .lecture_tabs {
      display: flex;
      top: 0;

      li {
        position: relative;
        width: 33.33%;
        padding: 18px 0;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.3px;
        text-align: center;
        color: #000000;
        cursor: pointer;

        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 0;
          content: '';
          background-color: #000000;
        }

        &.isActive {
          &::before {
            height: 3px;
          }
        }

        span {
          margin-left: 6px;
          color: #f24462;
        }
      }

      &.isFixed {
        position: fixed;
        top: 0;
        width: 786px;
        border-bottom: 1px solid #ececec;
        background: #ffffff;
        z-index: 10;

        & + .lecture_intro {
          margin-top: 56px;
        }
      }
    }

    .lecture_intro {
      padding: 45px 0 55px;
      border-bottom: solid 1px #ececec;
      white-space: pre-wrap;

      img {
        max-width: 100%;
        cursor: auto !important;
      }

      .intro_title {
        margin-bottom: 32px;

        img {
          width: 31px;
          margin-right: 18px;
        }
      }
    }

    .lecture_curriculum {
      padding: 45px 0 85px;
      border-bottom: solid 1px #ececec;

      .sample_video_title {
        margin-bottom: 23px;

        img {
          width: 31px;
          height: 24px;
          margin-right: 17px;
        }
      }

      .lecture_sample_wrap {
        margin-bottom: 44px;
        padding-bottom: 50px;
        border-bottom: 1px solid #ececec;

        .lecture_sample_list {
          padding: 0 37px 0 55px;
          border-bottom: 1px solid #ececec;

          .video_item {
            margin-bottom: 22px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &:last-child {
            border-bottom: 0 none;
          }
        }
      }

      .lecture_package_chapter_warp {
        .video_title {
          margin-bottom: 33px;

          img {
            width: 39px;
            height: 26px;
          }
        }

        .lecture_package_list {
          .package_item {
            height: 83px;
            margin-bottom: 7px;
            overflow: hidden;

            &_title {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 83px;
              padding: 0 28px 0 22px;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: -0.7px;
              color: #000000;
              background-color: #f8f8f8;
              box-sizing: border-box;

              img {
                width: 12px;
              }
            }

            &_number {
              display: inline-block;
              width: 28px;
              height: 28px;
              margin-right: 16px;
              border-radius: 50%;
              line-height: 28px;
              font-size: 11px;
              font-weight: bold;
              letter-spacing: -0.39px;
              text-align: center;
              color: #000000;
              background: #ffffff;
            }

            &:last-child {
              margin-bottom: 0;
            }

            &.isActive {
              height: auto;
            }
          }
        }

        .lecture_chapter_list {
          .lecture_chapter_item {
            padding: 25px 37px 25px 31px;
            border-bottom: 1px solid #ececec;

            &:first-child {
              padding-top: 0;
            }

            &:last-child {
              margin-bottom: 0;
              padding-bottom: 0;
              border-bottom: 0 none;
            }

            .chapter_title {
              margin-bottom: 22px;
              font-size: 20px;
              font-weight: 300;
              letter-spacing: -0.67px;
              color: #414141;

              .order {
                margin-right: 6px;
              }
            }

            .chapter_section_list {
              padding-left: 24px;

              .video_item {
                margin-bottom: 22px;

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }

          &.isPackage {
            padding: 25px 0;

            .lecture_chapter_item {
              border-bottom: 0 none;
            }
          }
        }
      }
    }

    .lecture_review {
      padding: 45px 0 0;

      .review_title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 45px;

        img {
          width: 42px;
          height: 42px;
          margin-right: 13px;
        }
      }

      .review_item {
        margin-bottom: 45px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .pagination_wrap {
      margin-top: 30px;
    }
  }

  .lecture_payment_info_pc {
    position: sticky !important;
    top: 61px !important;
    height: 100% !important;
  }

  .lecture_payment_info {
    width: 372px;
    margin-left: 36px;
    padding-top: 1.5rem;
    border-radius: 6px;
    box-shadow: 0 6px 10px 0 rgba(17, 17, 17, 0.1);

    .lecture_category {
      display: flex;
      flex-wrap: wrap;
      padding: 0 1.5rem;

      li {
        margin-right: 7px;
        margin-bottom: 7px;
        padding: 4px 18px;
        border-radius: 2px;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: -0.5px;
        text-align: center;
        color: #f24462;
        background-color: rgba(255, 101, 121, 0.07);

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .lecture_title {
      margin-bottom: 16px;
      padding: 0.25rem 1.5rem 0.625rem;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.53px;
      color: #000000;
      border-bottom: 1px solid #ececec;
    }

    .lecture_summary_info {
      padding: 0 1.5rem;
      > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
        font-size: 14px;
        letter-spacing: -0.25px;
        color: #000000;

        &:last-child {
          margin-bottom: 0;
        }

        &.schedule {
          strong {
            display: flex;
            align-items: center;
          }

          img {
            width: 15px;
            height: 15px;
            margin-left: 6px;
          }
        }

        .satisfaction {
          display: flex;
          align-items: center;

          img {
            width: 15px;
          }

          &_count {
            margin-left: 9px;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: -0.34px;
            color: #a2a2a2;
          }
        }
      }

      .schedule_list {
        padding: 0.75rem 0.5rem 0.25rem;

        li {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          cursor: pointer;

          &:last-child {
            margin-bottom: 0;
          }

          > img {
            margin-top: -16px;
            margin-right: 19px;
            width: 15px;
          }

          .timeline {
            margin-bottom: 1px;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: -0.5px;
            color: #000000;

            .deadline-wrap {
              margin-left: 10px;
              font-weight: 700;

              img {
                margin-top: -2px;
                margin-right: 2px;
              }
            }
          }

          .week_timeline {
            display: flex;
            font-size: 14px;
            letter-spacing: -0.5px;
            color: #000000;

            .active_week {
              margin-right: 4px;

              .week {
                position: relative;
                display: inline-block;
                margin-right: 4px;

                &::before {
                  position: absolute;
                  bottom: 2px;
                  right: -4px;
                  display: block;
                  content: ',';
                }

                &:last-child {
                  margin: 0;

                  &::before {
                    display: none;
                  }
                }
              }
            }

            .option_price-wrap {
              color: #f24462;
            }

            .deadline-wrap {
              margin-left: 10px;
              font-weight: 700;

              img {
                float: left;
                margin-top: -2px;
                margin-right: 2px;
              }
            }
          }

          // 오프라인 선택 불가능한 항목
          &.disAvailable {
            .timeline,
            .week_timeline {
              color: #acacac;
            }

            cursor: unset;
          }
        }
      }

      .lecture_payment_desc {
        display: block;
        padding-top: 16px;
        text-align: left;

        p {
          line-height: 17px;

          + p {
            line-height: 24px;
          }
        }

        .lecture_discount {
          margin-left: 6px;
          font-size: 14px;
          letter-spacing: -0.4px;
          color: #f24462;
        }

        .lecture_discounted_price {
          font-size: 20px;
          font-weight: bold;
          letter-spacing: -0.33px;
          color: #000000;

          .deadline-wrap {
            display: inline-flex;
            line-height: 20px;
            height: 20px;
            margin-left: 20px;
            font-weight: 700;
            font-size: 1rem;

            img {
              width: 16px;
              height: 16px;
              margin-right: 2px;
            }
          }
        }

        .lecture_cost {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.34px;
          color: #bebebe;

          &.hasDiscount {
            text-decoration: line-through;
          }
        }

        .deadline-wrap {
          margin-left: 10px;
          font-weight: 700;

          img {
            margin-bottom: -3px;
            margin-right: 2px;
          }
        }
      }
    }

    .lecture_payment {
      text-align: right;

      .lecture_payment_btn_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1.5rem 1rem;
      }

      .lecture_like_btn_wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 18px;
      }

      .lecture_like_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .vertical-line {
        width: 1px;
        height: 36px;
        margin: 18px 18px 0 18px;
        background-color: #e6e6e6;
      }

      .lecture_payment_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 18px;
        padding: 14px 0;
        border-radius: 5px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.3px;
        text-align: center;
        color: #fff;
        background-color: #f24462;
        cursor: pointer;

        img {
          width: 25px;
          margin-left: 11px;
        }
      }

      .is-waiting {
        background-color: rgba(242, 68, 98, 0.8);
      }

      .is-closed {
        background-color: #acacac;
        cursor: unset;
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0;

    .inner_wrap {
      padding: 0;

      .mobile_header {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 46px;
        padding: 0 24px;
        background-color: #ffffff;
        box-sizing: border-box;
        z-index: 10;

        img {
          position: static;
          width: 8px;
          margin-right: 20px;
          cursor: pointer;
          transform: translateY(0);
        }

        p {
          flex: 1;
        }
      }

      .isReverse {
        display: flex;
        flex-direction: column-reverse;
      }

      .info_wrap {
        float: unset;
      }

      .mobile_lecture_title_image_wrap {
        position: relative;

        img {
          width: 100%;
        }

        .appointment {
          position: absolute;
          top: 14px;
          left: 14px;
          padding: 9px 24px;
          border-radius: 5px;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.4px;
          color: #ffffff;
          background-color: #f24462;
        }
      }
    }

    .intro_title,
    .review_title,
    .video_title {
      font-size: 16px;
      letter-spacing: -0.63px;

      img {
        margin-right: 10px;
      }
    }

    .lecture_detail_info {
      width: 100%;
      padding: 11px 24px 0 24px;

      @media screen and (max-width: 767px) {
        padding-left: 16px;
        padding-right: 16px;
      }

      .lecture_title_image_wrap {
        display: none;
      }

      .lecture_tabs {
        li {
          padding: 13px 0;
          font-size: 13px;
          letter-spacing: -0.56px;

          &::before {
            height: 0;
          }

          &.isActive {
            &::before {
              height: 2px;
            }
          }
        }

        &.isFixed {
          top: 40px;
          left: 0;
          width: 100%;

          & + .lecture_intro {
            margin-top: 42px;
          }
        }
      }

      .lecture_intro {
        padding: 28px 0;

        ul {
          padding-left: 12px;

          li {
            padding-left: 12px;
          }
        }

        .intro_title {
          img {
            width: 21px;
          }
        }
      }

      .lecture_curriculum {
        padding: 45px 0 85px;
        border-bottom: solid 1px #ececec;

        .sample_video_title {
          margin-bottom: 23px;

          img {
            width: 21px;
            height: 16px;
          }
        }

        .lecture_sample_wrap {
          margin-bottom: 25px;
          padding-bottom: 25px;
          border-bottom: 0 none;

          .lecture_sample_list {
            padding: 0;

            .video_item {
              margin-bottom: 12px;
            }

            &:last-child {
              border-bottom: 0 none;
            }
          }
        }

        .lecture_package_chapter_warp {
          .video_title {
            margin-bottom: 24px;

            img {
              width: 21px;
              height: 16px;
            }
          }

          .lecture_package_list {
            .package_item {
              height: 69px;
              margin-bottom: 12px;

              &_title {
                display: flex;
                align-items: center;
                height: 69px;
                padding: 0 18px 0 12px;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: -0.57px;
              }
            }
          }

          .lecture_chapter_list {
            .lecture_chapter_item {
              padding: 21px 0 21px 0;
              border-bottom: 0 none;

              .chapter_title {
                margin-bottom: 16px;
                font-size: 16px;
                letter-spacing: -0.6px;

                .order {
                  margin-right: 4px;
                }
              }

              .chapter_section_list {
                padding-left: 0;

                .video_item {
                  margin-bottom: 12px;
                }
              }
            }

            &.isPackage {
              padding: 21px 0;

              .lecture_chapter_item {
                border-bottom: 0 none;
              }
            }
          }
        }
      }

      .lecture_review {
        padding: 24px 0 80px;

        .review_title {
          margin-bottom: 21px;

          img {
            margin-right: 9px;
          }
        }

        .review_item {
          margin-bottom: 22px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .review_more_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 20px 0;
          padding: 10px 0;
          border-radius: 5px;
          font-size: 12px;
          letter-spacing: -0.43px;
          color: #000000;
          background-color: #f8f8f8;
          cursor: pointer;

          img {
            width: 7px;
            margin-left: 6px;
          }
        }
      }
    }

    .lecture_payment_info {
      width: 100%;
      margin-left: 0;
      padding: 11px 24px 0;
      border-radius: 0;
      box-shadow: none;
      box-sizing: border-box;

      &.isActive {
        position: static !important;
        transform: unset;
      }

      .lecture_category {
        li {
          margin-bottom: 4px;
          padding: 4px 17px;
        }
      }

      .lecture_title {
        margin-bottom: 0;
        padding: 4px 0 16px;
        font-size: 16px;
        letter-spacing: -0.63px;
        border-bottom: 0 none;
      }

      .lecture_summary_info {
        padding-bottom: 25px;

        > div {
          margin-bottom: 4px;
          font-size: 12px;

          &.schedule {
            img {
              width: 12px;
              height: 12px;
              margin-left: 4px;
            }
          }
        }

        .schedule_list {
          padding: 12px 12px 0;

          li {
            display: flex;
            align-items: center;
            margin-bottom: 7px;

            > img {
              margin-right: 10px;
              width: 13px;
            }

            .timeline_wrap {
              .timeline {
                margin-bottom: 0;
              }

              .week_timeline {
                p {
                  span {
                    margin-right: 1px;
                  }
                }

                .deadline-wrap {
                  font-size: 0.8rem;

                  img {
                    width: 15px;
                    margin-top: -1px;
                  }
                }
              }

              &.isColumn {
                flex-direction: column;

                .week_timeline {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }

      .lecture_payment {
        &.isFixed {
          height: 4rem;
          padding: 0.75rem 1rem 0.125rem;
          position: fixed;
          bottom: 0;
          left: 0;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          background-color: #ffffff;
          box-sizing: border-box;
          z-index: 10;
          border-right-style: none !important;
          border-left-style: none !important;
          border-bottom-style: none !important;
          border-right-color: #ffffff;
          border-left-color: #ffffff;
          border-bottom-color: #ffffff;
          gap: 0.5rem;

          .lecture_payment_btn_wrap {
            display: flex;
            padding: 0;
            width: 100%;
            min-width: 40%;
            .lecture_payment_btn {
              white-space: nowrap;
            }
          }

          .timer-wrap {
            width: 100%;
            padding: 0;

            .timer-data {
              padding: 0;
              border: none;
              font-size: 0.75rem;
              display: flex;
              flex-direction: column;
              gap: 0;
              font-weight: 900;
            }

            .timer {
              font-size: 1rem;
              white-space: nowrap;
              margin-bottom: -0.25rem;
            }

            .progress-bar {
              display: none;
            }
          }
        }

        &.isNone {
          display: none;
        }

        text-align: center;

        .lecture_payment_desc {
          display: flex;
          flex-direction: column;
          text-align: left;

          .deadline-wrap {
            margin-left: 6px;
            font-weight: 700;
            font-size: 0.8rem;

            img {
              margin-bottom: -3px;
              margin-right: 2px;
            }
          }
        }

        .lecture_cost {
          &.isSelling {
            text-decoration: line-through;
          }
        }

        .lecture_discount {
          margin-left: 6px;
          font-size: 12px;
          letter-spacing: -0.4px;
          color: #f24462;
        }

        .lecture_discounted_price {
          font-size: 17px;
          letter-spacing: -0.18px;

          .deadline-wrap {
            margin-left: 8px;
            font-weight: 700;
            font-size: 0.8rem;

            img {
              margin-right: 1px;
            }
          }
        }

        .lecture_payment_btn {
          display: block;
          margin-top: 0;
          padding: 10px 0;
          font-size: 13px;

          img {
            display: none;
          }
        }

        .vertical-line {
          margin: 0 14px;
          height: 30px;
        }

        .lecture_like_btn_wrap {
          display: block;
          margin-top: 0;
          padding: 10px 0;
        }
      }
    }
  }
}

.video_item {
  display: flex;

  &_thumb {
    position: relative;
    width: 174px;
    height: 131px;
    overflow: hidden;
    cursor: pointer;

    > img:first-of-type {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 43px;
    }

    > img:last-of-type {
      width: 100%;
      height: 100%;
    }

    .video_item_thumb_overlap {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      z-index: 9;
      background-color: rgba(29, 29, 29, 0.35);

      > img {
        width: 54px;
      }
    }
  }

  &_info {
    display: flex;
    align-items: flex-start;
    flex: 1;
    padding: 6px 30px;
    box-sizing: border-box;
  }

  &_title {
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.82px;
    color: #000000;

    .order {
      margin-right: 4px;
    }
  }

  &_time {
    width: 60px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: #a2a2a2;
  }

  @media screen and (max-width: 767px) {
    &_thumb {
      position: relative;
      width: 150px;
      height: 113px;

      a {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 9;
        background-color: rgba(29, 29, 29, 0.35);

        img {
          width: 32px;
        }
      }

      .video_item_thumb_overlap {
        > img {
          width: 32px;
        }
      }
    }

    &_info {
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      padding: 4px 12px;
    }

    &_title {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: -0.51px;

      .order {
        margin-right: 4px;
      }
    }

    &_time {
      width: 60px;
      font-size: 13px;
      letter-spacing: -0.37px;
      color: #bebebe;
    }
  }
}
