.signup_form_wrap {
  .inner_wrap {
    display: flex;
    justify-content: space-between;
    padding-bottom: 51px;
  }

  .signup_image {
    width: 510px;
    min-height: 857px;
    margin-right: 75px;
    background-size: cover;

    .signup_intro_wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding-bottom: 128px;
      font-size: 22px;
      line-height: 1.55;
      color: #ffffff;
      white-space: pre-line;
      box-sizing: border-box;

      img {
        width: 58px;
        margin-bottom: 12px;
        margin-left: 48px;
      }

      .signup_intro_wrap-text {
        width: calc(510px - 48px * 2);
        margin-left: 48px;
      }
    }
  }

  .signup_form_filed {
    flex: 1;
    padding-top: 68px;

    .title {
      margin-bottom: 18px;

      h2 {
        font-size: 24px;
        letter-spacing: -0.4px;
        color: #000000;
      }

      .message {
        font-size: 12px;
        letter-spacing: -0.2px;
        color: #000000;

        span {
          color: #bebebe;
        }
      }
    }

    .success_message,
    .error_message {
      padding: 6px 10px;
      font-size: 12px;
      font-weight: 500;
    }

    .success_message {
      color: rgb(68, 230, 68);
    }

    .error_message {
      color: #f24462;
    }
  }

  #signup_form {
    width: 582px;
    padding-top: 22px;
    border-top: 1px solid #ececec;

    .label {
      margin-bottom: 6px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.82px;
      color: #000000;
    }

    .image_upload_filed {
      margin-bottom: 20px;

      .profile_image {
        position: relative;
        width: 72px;
        height: 72px;
        cursor: pointer;

        .profile_overlap_wrap {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 1px solid #ececec;
          overflow: hidden;
          box-sizing: border-box;
        }

        img {
          width: 100%;
          height: 100%;
        }

        .profile_upload_icon {
          position: absolute;
          bottom: -2px;
          right: 0;
          width: 22px;
          height: 22px;
          line-height: 22px;
          text-align: center;
          border-radius: 50%;
          background-color: #ffffff;

          img {
            width: 12px;
            height: 12px;
          }
        }

        .clear_profile_image {
          position: absolute;
          top: 0;
          right: -14px;
          width: 10px;
          cursor: pointer;
        }
      }
    }

    .text_filed_wrap {
      margin-bottom: 20px;

      input[type='text'],
      input[type='email'],
      input[type='password'] {
        width: 378px;
        margin-right: 30px;
        padding: 15px 20px;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.34px;
        outline: 0 none;
        border-radius: 5px;
        border: 1px solid #ececec;
        color: #000000;

        &::placeholder {
          color: #a2a2a2;
        }

        &:read-only {
          color: #a2a2a2;
          background: #ececec;
        }
      }

      .text_filed {
        display: flex;

        &.phone_number_filed,
        &.certification_filed {
          input {
            flex: 1;
          }
        }

        &.phone_number_filed {
        }

        &.certification_filed {
          margin-top: 10px;
          box-sizing: border-box;
        }

        .phone_number_inner_filed {
          display: flex;
          flex: 1;

          input[type='text'] {
            width: auto;
            flex: 1;
          }
        }
      }

      .benefit_consent_receive {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        letter-spacing: -0.2px;
        color: #000000;

        .checkbox_filed {
          display: flex;
          justify-content: space-between;
          cursor: pointer;

          > div {
            display: inherit;
            align-items: center;
            line-height: 1;

            .checkbox {
              margin: 0;
              margin-right: 10px;
            }
          }

          width: 174px;
        }
      }

      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 174px;
        padding: 15px 0 13px;
        border-radius: 5px;
        line-height: 1;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.2px;
        text-align: center;
        color: #ffffff;
        background-color: #000000;
        cursor: pointer;
      }
    }
  }

  button[type='submit'] {
    display: block;
    width: 100%;
    margin-top: 53px;
    padding: 18px 0 19px;
    border: 0 none;
    outline: 0 none;
    border-radius: 5px;
    color: #ffffff;
    background-color: #f24462;
    cursor: pointer;

    &:disabled {
      background-color: #bebebe;
      cursor: unset;
    }
  }

  .company_select_wrap {
    .MuiSelect-select {
      padding: 15px 20px;
    }

    .MuiInputBase-root {
      width: 378px;
      max-width: 100%;
    }

    .MuiInputLabel-root,
    .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #ececec;
    }
  }

  .country_select_wrap {
    margin-bottom: 15px;

    .MuiSelect-select {
      padding: 12px 14px;
      width: 203px;
    }

    .MuiInputLabel-root,
    .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #ececec;
    }
  }

  @media screen and (max-width: 767px) {
    .signup_image {
      display: none;
    }

    .signup_form_filed {
      width: 100%;
      padding: 33px 0 68px;

      .title {
        margin-bottom: 27px;

        h2 {
          font-size: 16px;
          letter-spacing: -0.63px;
        }

        .message {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.7px;
          color: #585858;

          span {
            display: none;
          }
        }
      }
    }

    #signup_form {
      width: 100%;
      padding-top: 0;
      border-top: 0 none;

      .label {
        margin-bottom: 12px;
      }

      .image_upload_filed {
        margin-bottom: 18px;

        .label {
          display: none;
        }

        .profile_image {
          width: 60px;
          height: 60px;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }

          .profile_upload_icon {
            bottom: -3px;
            right: -2px;
            // line-height: ;

            img {
              width: 10px;
              height: 10px;
            }
          }
        }
      }

      .text_filed_wrap {
        margin-bottom: 20px;

        input[type='text'],
        input[type='email'],
        input[type='password'] {
          flex: 1;
          width: auto;
          margin-right: 12px;
          padding: 14px 18px 15px;
          font-size: 11px;
          letter-spacing: -0.64px;

          &::placeholder {
            color: #a2a2a2;
          }
        }

        input[type='email'] {
          margin: 0;
        }

        .text_filed {
          &.user_name {
            input {
              margin-right: 0;
            }
          }

          &.phone_number_filed {
            flex-direction: column;
          }

          &.certification_filed {
            margin-top: 9px;
            padding-left: 0;
          }

          .phone_number_inner_filed {
            input {
              margin-right: 12px;
            }
          }
        }

        &.benefit_filed {
          .label {
            margin-bottom: 2px;
          }
        }

        .benefit_consent_receive {
          justify-content: flex-start;
          flex-direction: column;
          font-size: 11px;

          .checkbox_filed {
            justify-content: flex-start;
            width: auto;
            margin-top: 28px;
            font-size: 12px;

            > div {
              margin-right: 57px;

              input[type='checkbox'] {
                margin: 0;
                margin-right: 10px;
              }
            }
          }
        }

        .btn {
          width: 96px;
          font-size: 11px;
          letter-spacing: -0.64px;
        }
      }
    }

    button[type='submit'] {
      margin-top: 82px;
      padding: 16px 0 15px;

      &:disabled {
        background-color: #bebebe;
        cursor: unset;
      }
    }
    .country_select_wrap {
      .MuiSelect-select {
        width: 100%;
      }
    }
  }
}

.signup_confirm_modal_wrap {
  position: relative;
  top: 50%;
  width: 438px;
  margin: 0 auto;
  padding: 91px 69px;
  border-radius: 5px;
  text-align: center;
  background: #ffffff;
  transform: translateY(-50%);
  box-sizing: border-box;

  .complete_wrap-rectangle1 {
    position: absolute;
    width: 132px;
    right: 0;
    top: 30px;
  }

  .complete_wrap-rectangle2 {
    position: absolute;
    width: 38px;
    right: 0;
    top: 180px;
  }

  .complete_wrap-rectangle3 {
    position: absolute;
    width: 128px;
    left: 0;
    top: 167px;
  }

  .title {
    margin-bottom: 42px;
  }

  .message {
    position: relative;
    margin-bottom: 30px;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.82px;
    color: #000000;
    white-space: pre-line;
    z-index: 2;
  }

  .go_to_main {
    position: relative;
    display: block;
    width: 100%;
    padding: 18px 0 19px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: #ffffff;
    background-color: #f24462;
    cursor: pointer;
    z-index: 2;
  }

  @media screen and (max-width: 767px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 60px 30px 98px;
    height: auto;
    border-top-left-radius: 33px;
    border-top-right-radius: 33px;
    overflow: hidden;
    transform: translateY(0);

    .complete_wrap-rectangle1,
    .complete_wrap-rectangle2,
    .complete_wrap-rectangle3 {
      display: none;
    }

    .title {
      font-size: 20px;
      letter-spacing: -0.33px;
    }

    .message {
      font-size: 12px;
      letter-spacing: -0.7px;
    }

    .go_to_main {
    }
  }
}
