.my_nav_page {
  .mobile_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 46px;
    padding: 0 21px;
    border-bottom: 1px solid #ececec;
    box-sizing: border-box;
    background: #ffffff;

    img {
      width: 9px;
      margin-right: 20px;
      cursor: pointer;
    }

    p {
      flex: 1;
    }
  }

  .mobile_user_nav_content {
    padding: 43.5px 24px 0;

    .mobile_user_info {
      display: flex;
      align-items: flex-end;
      width: 100%;
      padding-bottom: 36px;
      border-bottom: 1px solid #ececec;

      .profile_img {
        width: 60px;
        height: 60px;
        margin-right: 19px;
        border-radius: 50%;
        overflow: hidden;
      }

      .user_desc {
        line-height: 1.5;

        h4 {
          font-size: 18px;
          letter-spacing: -0.71px;
          color: #000;
        }

        p {
          font-size: 13px;
          font-weight: 500;
          letter-spacing: -0.51px;
          color: #a2a2a2;

          .voucher {
            width: 15px;
            margin-right: 3px;
            margin-left: 0;
          }

          img {
            width: 3.5px;
            margin-left: 5px;
          }
        }
      }
    }

    .mobile_user_nav {
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 22px 27px 22px 19px;
        border-bottom: 1px solid #ececec;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.55px;

        img {
          width: 4px;
        }

        > a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.55px;
          color: #000000;
        }
      }

      .recent-notice-count {
        display: inline-block;
        width: 18px;
        height: 18px;
        text-align: center;
        margin-left: 5px;
        border-radius: 50%;
        background-color: rgba(230, 230, 230, 0.75);
      }
    }
  }
}
