.coupon_content {
  width: 846px;

  .title {
    margin-bottom: 23px;
  }

  .coupon_tabs {
    display: flex;
    margin-bottom: 25px;

    li {
      position: relative;
      width: 102px;
      margin-right: 7px;
      padding-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: -0.3px;
      text-align: center;
      color: #000000;
      cursor: pointer;

      &.isActive {
        color: #f24462;

        &::before {
          position: absolute;
          bottom: 0;
          left: 0;
          content: '';
          width: 100%;
          height: 3px;
          background-color: #f24462;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .coupon_input_form {
    position: relative;
    padding-bottom: 30px;
    border-bottom: 1px solid #ececec;

    input[type='text'] {
      width: 276px;
      padding: 10px 15px 11px;
      border-radius: 5px;
      border: solid 1px #ececec;
      outline: 0 none;
      box-shadow: none;
      background-color: #f8f8f8;

      &:focus {
        background-color: #ffffff;
      }
    }

    .enrollment_btn {
      margin-left: 30px;
      padding: 10px 40px 11px 39px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.26px;
      text-align: center;
      color: #fff;
      background-color: #f24462;
      cursor: pointer;
    }

    .coupon_error_message {
      position: absolute;
      bottom: 10px;
      left: 16px;
      font-size: 12px;
      color: #f24462;
    }
  }

  .coupon_list_wrap {
    padding-top: 29px;
    overflow: hidden;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;

    .coupon_tabs {
      display: flex;
      margin-bottom: 25px;

      li {
        padding-bottom: 8px;
        font-size: 14px;
      }
    }

    .coupon_input_form {
      display: flex;
      padding-bottom: 34px;
      border-bottom: 0 none;

      input[type='text'] {
        flex: 1;
        width: auto;
        padding: 16px 18px 13px;
      }

      .enrollment_btn {
        margin-left: 12px;
        padding: 16px 18px 13px;
        font-size: 11px;
      }
    }

    .coupon_list_wrap {
      padding-top: 0;
      overflow: hidden;
    }
  }
}
