.enrolment_wrap {
  display: flex;
  justify-content: center;

  .inner_wrap {
    display: flex;
    flex-direction: column;
    padding: 65px 16px 200px;

    h3 {
      margin-bottom: 22px;
      font-size: 20px;
      letter-spacing: -0.33px;
      color: #000000;
    }

    .content-wrap {
      width: 100%;
      max-width: 960px;
      padding: 0 1rem;

      .enrolment_loggedInUserInfo_wrap {
        margin-bottom: 32px;

        .enrolment_list_box {
          padding: 26px 20px;
          border-radius: 5px;
          border: solid 1px #ececec;
        }

        .enrolment_list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 4px;
          text-align: right;

          h4 {
            flex: 1;
            padding-right: 40px;
            font-size: 14px;
            letter-spacing: -0.25px;
            text-align: left;
            color: #000000;
          }

          .price_box {
            display: flex;
            flex-direction: column;

            .price {
              font-size: 16px;
              font-weight: bold;
              letter-spacing: -0.3px;
              color: #000000;

              &.hasDiscount {
                font-size: 12px;
                font-weight: 500;
                letter-spacing: -0.34px;
                color: #bebebe;
                text-decoration: line-through;
              }
            }

            .discounted_price {
              font-size: 16px;
              letter-spacing: -0.3px;
              color: #000000;
            }
          }
        }

        .coupon_select_wrap {
          .coupon_select_box {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 276px;
            padding: 14px 17px;
            border-radius: 5px;
            border: 1px solid #ececec;
            box-sizing: border-box;
            font-size: 12px;
            letter-spacing: -0.48px;
            color: #000000;

            img {
              cursor: pointer;
            }

            &.isNone {
              color: #a2a2a2;
            }

            .coupon_name {
              position: relative;
              width: 80%;
            }

            .clear_icon {
              position: absolute;
              top: 50%;
              right: -9px;
              width: 9px;
              transform: translateY(-50%);
            }

            .coupon_select_box_list {
              position: absolute;
              top: 50px;
              left: 0;
              width: 100%;
              font-size: 12px;
              color: #a2a2a2;
              border-radius: 5px;
              border: 1px solid #ececec;
              background-color: #ffffff;

              &.none_coupon {
                padding: 14px 17px;
                box-sizing: border-box;
              }

              li {
                padding: 8px 17px;
                font-size: 12px;
                font-weight: normal;
                color: #000000;
                box-sizing: border-box;

                &:hover {
                  font-weight: bold;
                  cursor: pointer;
                }
              }
            }

            .coupon_select_box-icon {
              position: absolute;
              width: 8px;
              right: 17px;
            }
          }
        }
      }

      .enrolment_form_wrap {
        .enrolment_form_header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title_wrap {
            display: flex;
            margin-bottom: 22px;

            h3 {
              margin: 0;
              padding: 0;
            }

            .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
              display: flex;
              column-gap: 0.25rem;
              align-items: center;
            }

            .MuiTypography-root.MuiFormControlLabel-label {
              font-size: 0.875rem !important;
            }
          }

          .message_wrap {
            margin-top: 22px;
            color: gray;
            font-size: 0.75rem;
          }
        }
      }

      span.fields--error-comment {
        color: red;
        white-space: nowrap;
        font-size: 0.75rem;
      }

      span.form-fields--error {
        width: 350px;
      }

      ul.form-fields-wrap {
        border: 1px solid #acacac;
        font-size: 0.875rem;

        > li {
          display: flex;
          position: relative;
          height: 36px;
          line-height: 36px;
          border-bottom: 1px solid #acacac;
          padding-right: 12px;

          &.error-list {
            border: #ff3c3c 1px solid !important;
            box-shadow: 0 0 0 1px #ff3c3c !important;
            z-index: 1;
          }

          .form-fields-content-wrap {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
          }

          &:last-child {
            border: none;
          }

          > label {
            display: inline-block;
            width: 160px;
            min-width: 160px;
            height: 100%;
            background-color: lightgray;
            text-align: center;
            line-height: 36px;
            margin-right: 1rem;
            border-right: 1px solid #acacac;
          }

          input {
            background: transparent;
            border: none;
            width: 100%;
            max-width: 350px;
            height: 90%;

            &:-webkit-autofill {
              transition: background-color 5000s ease-in-out 0s;
              -webkit-transition: background-color 9999s ease-out;
              -webkit-box-shadow: 0 0 0px 1000px white inset !important;

              &:hover,
              &:focus,
              &:active {
                transition: background-color 5000s ease-in-out 0s;
                -webkit-transition: background-color 9999s ease-out;
                -webkit-box-shadow: 0 0 0px 1000px white inset !important;
              }
            }

            &[type='date'],
            &[type='checkbox'] {
              max-width: 160px;
              line-height: 34px;
            }
          }

          > .react-datepicker-wrapper {
            width: 100%;
            max-width: 600px;
          }

          &.member-type-select-wrap {
            display: flex;
            height: 164px;

            > label {
              line-height: 164px;
              height: 100%;
            }

            div {
              padding: 10px 0;
              width: 100%;
              max-width: 600px;
              display: flex;
              flex-direction: column;

              > ul {
                width: 100%;

                li {
                  display: flex;
                  height: 34px;
                  line-height: 34px;
                  width: 100%;
                  column-gap: 1rem;
                  padding-right: 12px;

                  > span {
                    display: flex;
                    column-gap: 1rem;

                    > label {
                      padding-top: 1px;
                      width: 140px;
                      cursor: pointer;
                    }

                    + span {
                      color: #a7a7a7;
                      font-weight: 400;
                      font-size: 0.75rem;
                    }
                  }

                  input {
                    width: auto;
                    height: auto;
                    cursor: pointer;

                    &.etc-type-name {
                      border: 1px solid #bebebe;
                      width: 500px;
                      border-radius: 6px;
                      padding: 0 4px;
                    }
                  }
                }
              }
            }
          }
        }

        .user_education_etc__list {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
          }
        }

        .user_apply_reason__list {
          display: flex;
          height: auto;

          label {
            display: flex;
            flex: 0 0 auto;
            height: auto;
            align-items: center;
            justify-content: center;
          }

          textarea {
            flex: 1;
            width: 100%;
          }
        }
      }
    }

    .enrolment_form-title {
      font-size: 24px;
      font-weight: bold;
      height: 36px;
      margin-bottom: 40px;
    }
  }

  .enrolment_terms_box {
    margin-top: 20px;
    //width: 510px;
    //padding: 50px 34px 55px;
    //border-radius: 5px;
    //box-shadow: 0 6px 10px 0 rgba(17, 17, 17, 0.1);
    .checkbox {
      display: flex;
      height: 30px;
      align-items: center;
      margin-right: 6px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    h3 {
      padding-top: 2px;
      margin: 0;
    }

    .price_history {
      margin-bottom: 33px;
      padding: 27px 30px 24px;
      border-radius: 5px;
      border: solid 1px #ececec;

      .price_history_priceWrap {
        display: flex;
        justify-content: space-between;
        line-height: 2;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.34px;

        .label {
          color: #a2a2a2;
        }

        .price {
          color: #000;
          font-size: 12px;
          font-weight: 500;
        }

        .coupon {
          margin-bottom: 18px;
          color: #f24462;
        }
      }

      .total_price_history {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        border-top: 1px solid #ececec;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.5px;

        .label {
          color: #000;
        }

        .total_price {
          color: #f24462;
        }
      }
    }

    .enrolment_terms {
      .agreement {
        display: inline-flex;
        align-items: center;
        cursor: pointer;

        input[type='checkbox'] {
          width: 18px;
          height: 18px;
          margin: 0;
          padding: 0;
        }

        strong {
          margin-left: 13px;
          font-size: 16px;
          letter-spacing: -0.5px;
          color: #000000;
        }
      }

      .terms_list_box {
        margin-top: 8px;
        margin-bottom: 47px;
        padding-left: 31px;

        li {
          margin-bottom: 6px;
          font-weight: 500;
          letter-spacing: -0.4px;
          font-size: 0.875rem;

          span {
            margin-left: 7px;
            text-decoration: underline;
            cursor: pointer;
            color: #a2a2a2;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .enrolment_btn {
      transition: all 0.2s;
      display: block;
      width: 100%;
      padding: 18px 0 19px;
      border: 0 none;
      border-radius: 5px;
      outline: 0 none;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.4px;
      text-align: center;
      color: #ffffff;
      background-color: #f24462;
      cursor: pointer;

      &:disabled {
        background-color: #bebebe;
      }
    }
  }
}

.enrolment_term {
  .page-body {
    ol {
      display: list-item;
      list-style: outside none decimal;
    }

    li {
      list-style: inherit;
      text-align: left;

      + li {
        margin-top: 1rem;
      }
    }
  }

  > #enrolment_term-message-receive-instruction {
    max-width: 640px;
  }
}

@media screen and (max-width: 767px) {
  .enrolment_wrap {
    .inner_wrap {
      padding: 0;

      h3 {
        font-size: 1rem;
        letter-spacing: -0.33px;
        color: #000000;
      }

      .content-wrap {
        width: 100%;

        .enrolment_loggedInUserInfo_wrap {
          margin-bottom: 32px;
        }

        .enrolment_form_wrap {
          .enrolment_form_header {
            align-items: normal;
            justify-content: normal;
            flex-direction: column;

            .title_wrap {
              margin-bottom: 4px;

              h3 {
                margin: 0;
                padding: 0;
              }

              > label {
                span {
                  font-size: 0.875rem;
                }
              }
            }

            .message_wrap {
              margin-top: 0;
              color: gray;
              font-size: 0.6875rem;
            }
          }
        }

        ul.form-fields-wrap {
          border: 1px solid #acacac;
          font-size: 0.8125rem;
          height: 100%;

          > li {
            display: flex;
            height: 36px;
            line-height: 36px;
            border-bottom: 1px solid #acacac;
            padding-right: 12px;

            &:last-child {
              border: none;
            }

            > label {
              display: inline-block;
              width: 70px;
              min-width: 70px;
              height: 100%;
              background-color: lightgray;
              text-align: center;
              line-height: 36px;
              margin-right: 0.75rem;
              border-right: 1px solid #acacac;
            }

            input {
              background: transparent;
              border: none;
              width: 100%;

              &[type='checkbox'],
              &[type='radio'] {
                max-width: 28px;
                line-height: 24px;
              }
              &[type='date'] {
                border: 1px solid #ddd;
                height: 80%;
              }
            }

            > .react-datepicker-wrapper {
              max-width: 180px;
            }

            &.member-type-select-wrap {
              display: grid;
              height: 100%;
              grid-template-columns: auto 1fr;

              > label {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
              }

              div {
                padding: 10px 0;
                width: 50vw;
                display: flex;
                flex-direction: column;

                > ul {
                  width: 100%;

                  li {
                    display: flex;
                    flex-direction: column;
                    height: auto;
                    line-height: 24px;
                    width: 100%;
                    column-gap: 1rem;
                    padding-right: 12px;

                    > input {
                      width: auto;
                      height: auto;
                      cursor: pointer;

                      &.etc-type-name {
                        border: 1px solid #bebebe;
                        width: 100%;
                        border-radius: 6px;
                        padding: 0 4px;
                      }
                    }

                    > label {
                      padding-top: 1px;
                      width: 120px;
                      cursor: pointer;
                    }

                    > span {
                      color: #a7a7a7;
                      font-weight: 400;
                      font-size: 0.6875rem;
                      letter-spacing: -0.5px;
                      column-gap: 0.5rem;

                      label {
                        font-size: 0.8125rem;
                        color: #000;
                        letter-spacing: 0;
                      }

                      + span {
                        padding-left: 1.25rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .enrolment_form-title {
        display: none;
      }
    }

    .enrolment_terms_box {
      margin-top: 20px;
      //width: 510px;
      //padding: 50px 34px 55px;
      //border-radius: 5px;
      //box-shadow: 0 6px 10px 0 rgba(17, 17, 17, 0.1);
      .checkbox {
        display: flex;
        height: 30px;
        align-items: center;
        margin-right: 6px;

        img {
          width: 20px;
          height: 20px;
        }
      }

      h3 {
        padding-top: 2px;
        margin: 0;
      }

      .enrolment_terms {
        .agreement {
          display: inline-flex;
          align-items: center;
          cursor: pointer;

          input[type='checkbox'] {
            width: 18px;
            height: 18px;
            margin: 0;
            padding: 0;
          }

          strong {
            margin-left: 13px;
            font-size: 0.8125rem;
            letter-spacing: -0.5px;
            color: #000000;
          }
        }

        .terms_list_box {
          margin-top: 8px;
          margin-bottom: 47px;
          padding-left: 31px;

          li {
            margin-bottom: 6px;
            font-weight: 500;
            letter-spacing: -0.4px;

            span {
              margin-left: 7px;
              text-decoration: underline;
              cursor: pointer;
              color: #a2a2a2;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .enrolment_btn {
        transition: all 0.2s;
        display: block;
        width: 100%;
        padding: 18px 0 19px;
        border: 0 none;
        border-radius: 5px;
        outline: 0 none;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.4px;
        text-align: center;
        color: #ffffff;
        background-color: #f24462;
        cursor: pointer;

        &:disabled {
          background-color: #bebebe;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .inner_wrap {
        flex-direction: column;
        padding: 33px 24px 100px;
        box-sizing: border-box;

        h3 {
          margin-bottom: 0;
          font-size: 16px;
        }
      }

      .enrolment_form {
        width: 100%;

        h2 {
          display: none;
        }

        .enrolment_loggedInUserInfo_wrap {
          margin-bottom: 32px;

          h3 {
            margin-bottom: 11px;
          }

          .enrolment_list_box {
            padding: 20px 20px 33px;
          }

          .enrolment_list {
            flex-direction: column;
            justify-content: unset;
            align-items: flex-start;
            text-align: unset;

            h4 {
              margin-bottom: 14px;
              padding: 0;
              font-weight: 500;
              letter-spacing: -0.55px;
            }

            .price_box {
              justify-content: flex-end;
              width: 100%;
              text-align: right;
            }
          }

          .coupon_select_wrap {
            display: flex;
            justify-content: space-between;

            .price_box {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              width: auto;
              margin-left: 15px;
              text-align: right;

              .price {
                font-size: 16px;
                font-weight: bold;
                letter-spacing: -0.34px;

                &.hasDiscount {
                  font-size: 12px;
                  font-weight: 500;
                  color: #bebebe;
                  text-decoration: line-through;
                }
              }

              .discounted_price {
                font-size: 16px;
                letter-spacing: -0.3px;
                color: #000000;
              }
            }

            .coupon_select_box {
              flex: 1;
              width: auto;
              padding: 12px 23px 12px 21px;
            }
          }
        }

        .enrolment_form_wrap {
          h3 {
            margin-bottom: 22px;
          }
        }
      }

      .enrolment_terms_box {
        padding: 0;

        h3 {
          margin-bottom: 0;
        }

        .price_history {
          margin-bottom: 89px;
          padding-bottom: 15px;
          padding: 0;
          border-radius: 0;
          border: 0 none;
        }

        .enrolment_terms {
          .agreement {
            display: flex;
            align-items: center;

            strong {
              margin-left: 22px;
              font-size: 16px;
            }
          }

          .terms_list_box {
            margin-top: 15px;
            margin-bottom: 36px;
            padding-left: 24px;
          }
        }

        .enrolment_btn {
          padding: 16px 0 14px;
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .popup-content .enrolment_term {
    height: 100%;

    h1 {
      font-size: 1.5em;
    }

    .page-body {
      font-size: 0.875rem;
      overflow-y: scroll;
      display: block;
      max-height: 80%;
      padding: 0 0.5rem;
      margin: 0 -0.5rem;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:vertical {
        width: 4px;
      }

      &::-webkit-scrollbar:horizontal {
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 3px;
        border: 1px solid #ffffff;
      }

      &::-webkit-scrollbar-track {
        border-radius: 3px;
        background-color: #ffffff;
      }

      ul,
      ol {
        padding-left: 1rem;
      }

      > ul,
      > ol {
        padding-left: 1.25rem;
      }

      ul {
        list-style: outside none square;
        margin-top: 0.5rem;
        font-weight: bold;

        li {
          list-style: inherit;
          text-align: left;

          + li {
            margin-top: 0.25rem;
          }

          ul {
            list-style: outside none circle;
            margin-top: 0;
            font-weight: normal;
          }
        }
      }
    }
  }
}
