.coupon_detail_modal_wrap {
  position: relative;
  top: 50%;
  width: 438px;
  height: 460px;
  margin: 0 auto;
  padding: 73px 37px 0;
  border-radius: 5px;
  text-align: center;
  background: #ffffff;
  transform: translateY(-50%);
  overflow: hidden;
  box-sizing: border-box;

  .title {
    margin-bottom: 42px;
  }

  .close_btn {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 15px;
    height: 15px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  .link_list_wrap {
    height: calc(100% - 66px);
    overflow-y: auto;

    a {
      display: block;
      margin-bottom: 5px;
      padding: 12px 14px;
      border-radius: 5px;
      font-size: 13px;
      letter-spacing: -0.22px;
      text-align: left;
      color: #000000;
      background-color: #f8f8f8;
    }
  }

  @media screen and (max-width: 767px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 36px 24px;
    border-top-left-radius: 45px;
    border-top-right-radius: 45px;
    transform: translateY(0);

    .mobile_title {
      margin-bottom: 15px;
      padding-left: 15px;
      text-align: left;
      white-space: pre-line;
    }

    .link_list_wrap {
      max-height: 260px;
    }
  }
}
