.payment_message_modal_wrap {
  position: relative;
  top: 50%;
  width: 438px;
  margin: 0 auto;
  padding: 91px 69px 69px;
  border-radius: 5px;
  text-align: center;
  background: #ffffff;
  transform: translateY(-50%);
  box-sizing: border-box;

  .complete_wrap-rectangle1 {
    position: absolute;
    width: 132px;
    right: 0;
    top: 30px;
  }

  .complete_wrap-rectangle2 {
    position: absolute;
    width: 38px;
    right: 0;
    top: 180px;
  }

  .complete_wrap-rectangle3 {
    position: absolute;
    width: 128px;
    left: 0;
    top: 167px;
  }

  .modal_title {
    margin-bottom: 0;
  }

  .close_btn {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  .message {
    position: relative;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.82px;
    color: #000000;
    z-index: 3;

    &.confirm_message {
      margin: 28px 0 67px;
      white-space: pre-line;
    }

    &.refund_message {
      margin: 42px 0 125px;
    }
  }

  .refund_info_list {
    position: relative;
    width: 100%;
    padding: 34px 27px 81px;
    box-sizing: border-box;
    z-index: 2;

    p {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
      font-size: 15px;
      letter-spacing: -0.88px;
      color: #000000;

      span {
        font-weight: 500;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .link_to_btn,
  .confirm_btn {
    display: block;
    width: 100%;
    padding: 18px 0 19px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: #ffffff;
    background-color: #f24462;
  }

  .confirm_btn {
    cursor: pointer;
  }

  @media screen and (max-width: 767px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 60px 30px 98px;
    height: auto;
    border-top-left-radius: 33px;
    border-top-right-radius: 33px;
    overflow: hidden;
    transform: translateY(0);

    .complete_wrap-rectangle1,
    .complete_wrap-rectangle2,
    .complete_wrap-rectangle3 {
      display: none;
    }

    .modal_title {
      font-size: 20px;
      letter-spacing: -0.33px;
    }

    .message {
      font-size: 12px;
      letter-spacing: -0.7px;

      &.confirm_message {
        margin: 25px 0 39px;
        white-space: pre-line;
      }

      &.refund_message {
        margin: 15px 0 67px;
      }
    }
  }
}
