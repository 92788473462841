.payment_content {
  padding-bottom: 120px;

  .title {
    margin-bottom: 17px;
  }

  .message {
    margin-bottom: 21px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: #484848;

    strong {
      font-weight: bold;
      color: #080808;
    }
  }

  .date_picker_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 27px;

    .tilde {
      margin: 0 20px;
    }

    .react-datepicker-wrapper {
      width: 204px;

      .react-datepicker__input-container {
        input {
          width: 100%;
          padding: 11px 22px 10px;
          border-radius: 5px;
          border: solid 1px #ececec;
          background-color: #f8f8f8;

          &:focus {
            background-color: #ffffff;
          }
        }
      }
    }

    .lookup_btn {
      display: inline-block;
      width: 144px;
      margin-left: 30px;
      padding: 10px 0 11px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.26px;
      text-align: center;
      color: #fff;
      background-color: #f24462;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0 24px;

    .message {
      margin-bottom: 23px;
      line-height: 1.5;
      font-size: 12px;
      letter-spacing: -0.43px;
      color: #000000;
      white-space: pre-wrap;
    }

    .date_picker_wrap {
      display: none;
    }
  }
}

.payment_item {
  position: relative;
  width: 816px;
  margin-bottom: 12px;
  padding: 30px 22px 32px;
  border-radius: 5px;
  box-shadow: 0 20px 30px -20px rgba(17, 17, 17, 0.22);
  border: solid 1px #ececec;
  box-sizing: border-box;

  .payment_item_info {
    width: 50%;

    .title_wrap {
      padding-bottom: 14px;
      border-bottom: 1px solid #ececec;

      .payment_item {
        color: #000000;

        &_status {
          display: inline-block;
          margin-bottom: 2px;
          font-size: 14px;
          letter-spacing: -0.75px;
          color: #f24462;

          &.isPaid {
            color: #000000;
          }
        }

        &_title {
          font-size: 16px;
          letter-spacing: -0.34px;
        }
      }
    }

    .info_list_wrap {
      padding-top: 14px;

      .info_list {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.34px;
        color: #000000;

        .label {
          color: #a2a2a2;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .date_info {
    display: flex;
    position: absolute;
    top: 30px;
    right: 22px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.51px;
    color: #a2a2a2;

    > span {
      position: relative;
      padding: 0 16px;

      strong {
        margin-right: 4px;
      }

      &:first-child {
        padding-left: 0;

        &::before {
          position: absolute;
          top: 0;
          right: 0;
          content: '|';
        }
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .manage_list_wrap {
    position: absolute;
    bottom: 32px;
    right: 22px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.4px;
    text-align: right;
    color: #c6c6c6;
    text-decoration: underline;

    span {
      cursor: pointer;

      &.waiting {
        color: #a2a2a2;
      }
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 22px;
    padding: 30px 22px 19px;

    .payment_item_info {
      width: 100%;

      .title_wrap {
        .payment_item {
          &_status {
            font-size: 14px;
          }

          &_title {
            font-size: 16px;
            letter-spacing: -0.34px;
          }
        }
      }

      .info_list_wrap {
        margin-bottom: 18px;
      }
    }

    .date_info {
      position: absolute;
      top: 10px;
      font-size: 9px;
      letter-spacing: -0.39px;
      box-sizing: border-box;

      &.isColumn {
        display: flex;
        flex-direction: column;
        right: 22px;

        span {
          width: 100%;
          justify-content: flex-end;
          text-align: right;
          margin: 0;
          padding: 0;

          &::before {
            display: none;
          }
        }
      }
    }

    .manage_list_wrap {
      position: static;
      display: flex;
      flex-direction: row;
      font-size: 12px;
      text-align: center;
      text-decoration: none;

      span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: calc(50% - 6px);
        margin-right: 6px;
        padding: 9px 12px;
        border-radius: 5px;
        border: 1px solid #ececec;
        box-sizing: border-box;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
