.package-lecture {
  padding: 0 24px;

  h1 {
    font-size: 17px;
  }

  .package-lecture__child-lecture-card {
    margin-top: 23px;
    padding: 24px 19px;
    border-radius: 5px;
    box-shadow: 0 6px 10px 0 rgba(17, 17, 17, 0.1);

    h2 {
      font-size: 14px;
      font-weight: 500;
    }

    hr {
      margin-top: 8px;
      height: 1px;
      border: 0px;
      background-color: #ececec;
    }

    button,
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 42px;
      font-size: 13px;
      font-weight: 500;
      color: white;
      background-color: #bebebe;
      border-radius: 5px;
      pointer-events: none;
    }

    button::after {
      content: '';
      margin-left: 8px;
      background-image: url('../../../../../Assets/Images/icon-play.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 18px;
      height: 18px;
    }

    hr + button {
      margin-top: 20px;
    }

    a.file_download {
      background-color: transparent;
      color: #f22672;
      border-color: #f22672;
      border-style: solid;
      border-width: 1px;
      margin-top: 8px;

      &:after {
        display: none;
      }
    }
  }

  h1 + .package-lecture__child-lecture-card {
    margin-top: 28px;
  }
}

.package-lecture--ongoing {
  .package-lecture__child-lecture-card {
    button {
      pointer-events: visible;
      background-color: #f24462;
    }
  }
}
